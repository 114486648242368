@font-face {
  font-family: Montserrat;
  src: url(../font/Montserrat-ExtraBold.ttf);
  font-style: normal;
  font-weight: 800;
}
@font-face {
  font-family: Montserrat;
  src: url(../font/Montserrat-Bold.ttf);
  font-style: normal;
  font-weight: bold;
}
@font-face {
  font-family: Montserrat;
  src: url(../font/Montserrat-SemiBold.ttf);
  font-style: normal;
  font-weight: 600;
}
@font-face {
  font-family: Montserrat;
  src: url(../font/Montserrat-Medium.ttf);
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: Montserrat;
  src: url(../font/Montserrat-Regular.ttf);
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: Caveat;
  src: url(../font/Caveat-Bold.ttf);
  font-style: normal;
  font-weight: bold;
}
