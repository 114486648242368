#page {
	display: flex;
	flex-direction: column;
	margin: 0 auto;
	min-width: 1000px;
	// max-width: 1920px;
	min-height: 100vh;
	overflow: hidden;
}

#page-content {
	flex-grow: 1;
	margin-top: 120px;
}

.container-fluid {
	max-width: 1200px;
}

.row-gallery {
	margin: 0 -3px -6px;
	> .col,
	> [class*="col-"] {
		margin-bottom: 6px;
	  padding-right: 3px;
	  padding-left: 3px;
	}
}

.group-select {
	display: flex;
	margin-right: -20px;
	.btn {
		margin-right: 20px;
		color: inherit;
		border: 2px solid #e0e0e0;
		background-color: transparent;
		&.active {
			color: #fff;
			font-weight: bold;
			border-color: transparent;
			background-color: #ea3d56;
		}
	}
}

.logo {
  display: inline-block;
  width: 100%;
  max-width: 200px;
  img {
    width: 100%;
  }
}

.h-decor {
	margin-bottom: 40px;
	&:after {
		display: block;
		margin: 20px auto 0;
		content: '';
		width: 36px;
		height: 7px;
		background-color: #ea3d56;
	}
}

.text-label {
	display: inline-block;
	padding: 8px 20px;
	color: #fff;
	background-color: #eb3d56;
}

.text-underline {
	display: inline-block;
	padding-bottom: 2px;
	border-bottom: 1px solid #eb3d56;
}