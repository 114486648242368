.catalog-card {
	position: relative;
	display: flex;
	align-items: flex-end;
	margin-bottom: 6px;
	min-height: 494px;
	color: #fff;
	text-align: center;
	background: center no-repeat;
	background-size: cover;
	&:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(32, 32, 36, .55);
	}
}

.catalog-card__content {
	position: relative;
	width: 100%;
	padding: 44px 20px;
}

.catalog-card__label {
	display: inline-block;
	margin-bottom: 14px;
	height: 36px;
	padding: 0 15px;
	line-height: 36px;
	font-size: 16px;
	background-color: #ea3d56;
}

.catalog-card__title {
	margin-bottom: 20px;
	line-height: 33px;
	font-size: 23px;
	font-weight: 600;
}

.catalog-card__price {
	font-size: 33px;
	font-weight: 800;
}

.catalog-card__service {
	display: inline-block;
	margin-bottom: 40px;
	padding-bottom: 2px;
	font-size: 14px;
	border-bottom: 1px solid #ea3d56;
}