.about {
	position: relative;
	display: flex;
	&:before {
		position: absolute;
		content: '';
		top: 85px;
		right: 0;
		width: 70%;
		height: 330px;
		background-color: #eb3d56;
		
	}
}

.about__cards {
	position: relative;
	flex-grow: 1;
	margin-right: -24%;
	z-index: 1;
}

.about__img {
	position: relative;
	margin-top: 240px;
	min-width: 41%;
	img {
		width: 86%;
		box-shadow: 0 5px 29px rgba(0, 0, 0, .03);
	}
}

.about-card {
	position: relative;
	padding: 50px 30px 60px 50px;
	&:before {
		position: absolute;
		top: 12px;
		right: 30px;
		line-height: 80px;
		font-family: Caveat, sans-serif;
		font-size: 80px;
		font-weight: bold;
		opacity: .11;
	}
}

.about-card-1 {
	margin-left: 43%;
	width: 57%;
	padding-left: 70px;
	background-color: #fff;
	box-shadow: 0 5px 29px rgba(0, 0, 0, .13);
	&:before {
		content: '01';
	}
}

.about-card-2 {
	margin-top: -180px;
	margin-left: 3%;
	width: 45%;
	padding-bottom: 100px;
	color: #fff;
	background-color: #32323a;
	box-shadow: 0 5px 29px rgba(0, 0, 0, .13);
	&:before {
		content: '02';
	}
}

.about-card-3 {
	margin-top: -25px;
	margin-left: 8%;
	width: 57%;
	padding-bottom: 0;
	&:before {
		top: 42px;
		content: '03';
	}
	&:after {
		position: absolute;
		top: -40px;
		left: 0;
		bottom: 32px;
		content: '';
		width: 8px;
		background-color: #ea3d56;
	}
}

.about-card__title {
	margin-bottom: 10px;
	font-family: Caveat, sans-serif;
	font-size: 30px;
	font-weight: bold;
	text-transform: uppercase;
}

.about-card__links {
	display: flex;
	margin-right: -20px;
	margin-bottom: 20px;
}

.about-card__link {
	margin-right: 20px;
	font-weight: bold;
	text-transform: uppercase;
	border-bottom: 2px solid #ea3d56;
}

.about-card__text {
	line-height: 24px;
	font-size: 14px;
}