.main {
	position: relative;
	display: flex;
	// align-items: center;
	margin-top: -120px;
	min-height: 100vh;
	padding-top: 120px;
	padding-bottom: 120px;
	color: #fff;
	background: center no-repeat;
	background-size: cover;
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		content: '';
		background-color: rgba(32, 32, 36, .64);
	}
	.text-label {
		padding: 8px 30px;
		font-size: 27px;
	}
}

.btn-request {
	position: absolute;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	right: 15px;
	bottom: -78px;
	height: 58px;
	padding: 0 20px;
	color: #fff;
	border-radius: 29px;
	border: none;
	background-color: #ea3d56;
	> span {
		position: relative;
		z-index: 1;
	}
	&:before, &:after {
		position: absolute;
		content: '';
		border-radius: 41px;
	}
	&:before {
		top: -6px;
		left: -6px;
		right: -6px;
		bottom: -6px;
		background-color: rgba(234, 61, 86, .59);
	}
	&:after {
		top: -12px;
		left: -12px;
		right: -12px;
		bottom: -12px;
		background-color: rgba(234, 61, 86, .37);
	}
}






// .b-main {
// 	padding: 60px 0;
// 	color: #fff;
// 	background-position: center;
// 	background-repeat: no-repeat;
// 	background-size: cover;
// }

// .main-menu {
// 	display: flex;
// 	justify-content: center;
// 	list-style: none;
// 	margin: 0;
// 	margin-right: -76px;
// 	padding: 0;
// 	font-size: 14px;
// 	white-space: nowrap;
// 	> li {
		
// 		margin-right: 76px;
// 	}
// 	li {
// 		position: relative;
// 		&:hover {
// 			> ul {
// 				visibility: visible;
// 				opacity: 1;
// 			}
// 		}
// 	}
// 	> li > a {
// 		position: relative;
// 		display: inline-block;
// 		// padding: 10px 0;
// 		&:before {
// 			position: absolute;
// 			content: '';
// 			bottom: -10px;
// 			left: 50%;
// 			width: 0;
// 			height: 4px;
// 			background-color: currentColor;
// 			transition: left .3s, width .3s;
// 		}
// 		&:hover {
// 			text-decoration: none;
// 			&:before {
// 				left: 0;
// 				width: 100%;
// 			}
// 		}
// 	}
// }

// .main-submenu {
// 	position: absolute;
// 	top: 100%;
// 	left: -20px;
// 	list-style: none;
// 	margin: 0;
// 	margin-top: 6px;
// 	padding: 10px 20px;
// 	color: #242424;
// 	background-color: #fff;
// 	visibility: hidden;
// 	opacity: 0;
// 	transition: visibility .3s, opacity .3s;
// 	li {
// 		margin-bottom: 8px;
// 		&:last-child {
// 			margin-bottom: 0;
// 		}
// 	}
// 	a {
// 		&:hover {
// 			color: #0c743b;
// 			text-decoration: none;
// 		}
// 	}
// }