.news-card {
	position: relative;
}

.news-card__date {
	position: absolute;
	top: 20px;
	left: 20px;
 	width: 95px;
 	height: 37px;
 	padding: 0 16px;
 	line-height: 37px;
 	font-size: 13px;
 	color: #fff;
 	background-color: #6585c2;
}

.news-card__img {
	position: relative;
	&:before {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(32, 32, 36, .1);
	}
	img {
		width: 100%;
	}
}

.news-card__content {
	padding: 14px 10px;
}

.news-card__title {
	margin-bottom: 10px;
	font-size: 20px;
	font-weight: bold;
}

.news-card__data-item {
	position: relative;
	margin-bottom: 4px;
	padding-left: 20px;
	[class^="icon-"], [class*=" icon-"] {
		position: absolute;
		top: 2px;
		left: 0;
		font-size: 12px;
		color: #ea3d56;
		&:before {
			margin: 0;
		}
	}
}

.news-wide {
	position: relative;
	display: flex;
	align-items: center;
	margin: 0 auto 50px;
	max-width: 800px;
	.news-card__date {
		left: 26px;
	}
	.news-card__img {
		width: 50%;
	}
	.news-card__content {
		width: 50%;
		padding-left: 30px;
	}
	.news-card__title {
		font-size: 22px;
	}
	&:nth-child(2n + 2) {
		.news-card__content {
			order: -1;
			padding-left: 0;
			padding-right: 30px;
		}
	}
	&:last-child {
		margin-bottom: 0;
	}
}




