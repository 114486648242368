body {
	font-family: Montserrat, sans-serif;
	color: #1c1c21;
	font-size: 14px;
}

hr {
  margin: 20px 0;
  color: inherit;
  background-color: #e5e5e5;
  border: none;
  opacity: 1;
}

a {
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: none;
	}
}

button {
	&:focus {
		outline: none;
	}
}

b, strong {
  font-weight: bold;
}

img {
	max-width: 100%;
}

sub {
	bottom: 0;
}

// .h-color {
// 	color: #0c743b;
// }

.form-group {
	margin-bottom: 10px;
}

.form-label {
	margin-bottom: 8px;
	margin-left: 5px;
	font-weight: 600;
}

.form-item {
	position: relative;
	[class^="icon-"], [class*=" icon-"] {
		position: absolute;
		top: 50%;
		right: 14px;
		margin-top: -8px;
		width: 16px;
		height: 16px;
		line-height: 16px;
		text-align: right;
		&:before {
			margin: 0;
		}
	}
	.form-control {
		padding-right: 44px;
	}
}

.form-control {
	padding: 11px 20px;
	&:focus {
		box-shadow: none;
	}
}

// .form-control-sm {
// 	padding: 8px 19px;
// }

textarea.form-control {
	min-height: 170px;
	max-height: 200px;
}

p {
	margin-bottom: 20px;
	&:last-child {
		margin-bottom: 0;
	}
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
	margin-bottom: 20px;
	font-weight: bold;
	&:last-child {
		margin-bottom: 0;
	}
	strong {
		height: 38px;
		padding: .25em .6em;
		color: #fff;
		font-weight: normal;
		background-color: #ea3d56;
	}
}

.h1 {
	font-size: 65px;
}

.h2 {
  font-size: 50px;
}

.h3 {
  font-size: 35px;
}

.h4 {
	font-size: 28px;
}

.h5, {
	line-height: 38px;
  font-size: 25px;
}

.h6 {
  font-size: 20px;
}

// .my-list {
// 	margin-bottom: 20px;
// 	list-style: none;
// 	padding-left: 26px;
// 	line-height: 32px;
// 	font-size: 17px;
// 	&:last-child {
// 		margin-bottom: 0;
// 	}
// 	li {
// 		&:before {
// 			position: absolute;
// 			content: '';
// 			margin-top: 12px;
// 			margin-left: -26px;
// 			width: 8px;
// 			height: 8px;
// 			border-radius: 50%;
// 			background-color: #08ae54;
// 		}
// 	}
// }

.typography {
	line-height: 24px;
	font-size: 14px;

	h1, h2, h3, h4, h5, h6,
	p, ul, ol, blockquote, img {
		margin-bottom: 24px;
		&:last-child {
			margin-bottom: 0;
		}
	}
	a {
		text-decoration: underline;
	}
	ul {
		margin-bottom: 20px;
		list-style: none;
		padding-left: 38px;
		&:last-child {
			margin-bottom: 0;
		}
		li {
			margin-bottom: 8px;
			&:before {
				position: absolute;
				content: '';
				margin-top: 8px;
				margin-left: -20px;
				width: 6px;
				height: 6px;
				background-color: $red;
			}
		}
	}
	blockquote {
		position: relative;
		margin-top: 40px;
		padding: 60px 60px 50px;
		line-height: 27px;
		font-size: 17px;
		background-color: #f7f8fa;
		&:after {
			position: absolute;
			content: '';
			top: -19px;
			left: 60px;
			width: 56px;
			height: 46px;
			background: url(../img/quote-2.png) center no-repeat;
			background-size: contain;
		}
	}
	&:first-child {
		margin-top: 20px;
	}
}
