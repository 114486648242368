.event-cards {
	// padding-right: 140px;
}

.event-card {
	display: flex;
}

.event-card__sidebar {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	min-width: 140px;
	padding: 20px;
	background-color: #eff0f1;
}

.event-card__content {
	flex-grow: 1;
	min-width: 0;
	padding-top: 20px;
	padding-left: 40px;
}

.event-card__nav {
	position: relative;
	width: 30%;
	padding-right: 34px;
	z-index: 1;
}

.event-card__card {
	position: relative;
	width: 70%;
	padding: 40px 40px 30px 80px;
	background-color: #fff;
	box-shadow: 0 5px 29px rgba(0, 0, 0, .07);
}

.event-card__card-tab {
	padding-right: 40px;
}

.event-card__count {
	position: relative;
	align-self: center;
	width: 140px;
	min-width: 138px;
	height: 115px;
	line-height: 115px;
	font-size: 25px;
	font-style: italic;
	text-align: center;
	border: 1px solid #e63d54;
	border-left: none;
	.slider-controls {
		position: absolute;
		flex-direction: column;
		top: 50%;
		margin-top: -38px;
		margin-left: -17px;
	}
	.slider-next {
		margin-left: 0;
		margin-top: 4px;
	}
}

.event-logo {
	font-size: 11px;
	font-weight: bold;
	text-transform: uppercase;
	img {
		display: block;
		margin: 10px auto;
	}
}

.event-date {
	position: relative;
	padding-left: 13px;
	line-height: 16px;
	font-size: 12px;
	&:before {
		position: absolute;
		content: '';
		top: 4px;
		left: 0;
		bottom: 4px;
		width: 4px;
		background-color: #ea3d56;
	}
}

.event-nav {
	list-style: none;
	margin: -14px 0;
	max-width: 200px;
	padding: 0;
	li {
		margin: 40px 0;
	}
	a {
		position: relative;
		display: block;
		text-decoration: underline;
		&:before {
			position: absolute;
			content: '';
			top: -8px;
			left: -14px;
			right: -14px;
			bottom: -8px;
			border: 1px solid #ea3d56;
			opacity: 0;
		}
		&:after {
			position: absolute;
			content: '';
			top: 50%;
			right: -104px;
			width: 90px;
			height: 1px;
			background-color: #ea3d56;
			opacity: 0;
		}
		&.active {
			color: #ea3d56;
			font-weight: bold;
			&:before, &:after {
				opacity: 1;
			}
		}
	}
}

.focument-item,
.video-item {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 150px;
	color: #fff;
	background: center no-repeat;
	background-size: cover;
	&:before {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(36, 36, 43, .6);
	}
}

.focument-item {
	&:before {
		display: flex;
		justify-content: center;
		align-items: center;
		font-family: "fontello";
		content: '\e809';
		font-size: 20px;
	}
}

.video-item__play {
	display: flex;
	width: 50px;
	height: 50px;
	padding-left: 6px;
	border-radius: 50%;
	background-color: rgba(255, 255, 255, .11);
	&:after {
		display: block;
		content: '';
		margin: auto;
		border-top: 8px solid transparent;
		border-left: 13px solid #ec5269;
		border-bottom: 8px solid transparent;
	}
}



