// Файл для переопределения значениий встроенных переменных.

$spacer: 4px;
$spacers: (
  0: 0,
  1: $spacer,
  2: ($spacer * 2),     // 8px
  3: ($spacer * 3),     // 12px
  4: ($spacer * 4),     // 16px
  5: ($spacer * 5),     // 20px
  6: ($spacer * 7.5),   // 30px
  7: ($spacer * 10),    // 40px
  8: ($spacer * 12.5),  // 50px
  9: ($spacer * 15),    // 60px
  10: ($spacer * 17.5), // 70px
  11: ($spacer * 20),   // 80px
  12: ($spacer * 22.5)  // 90px
);

// Элементы формы
$input-height: 45px;
$input-font-size: 14px;
$input-font-weight: normal;
$input-color: #1c1c21;
$input-border-width: 1px; 
$input-border-color: #ea3d56;
$input-border-radius: 0;
// $form-grid-gutter-width: 8px;
$input-placeholder-color: #818080;
