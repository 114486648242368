.publication-card {
	display: flex;
	margin-bottom: 20px;
	min-height: 210px;
	padding: 20px;
	box-shadow: $shadow;
	&:last-child {
		margin-bottom: 0;
	}
}

.publication-card__img {
	width: 164px;
	min-width: 164px;
	background: #eee center no-repeat;
	background-size: cover;
}

.publication-card__content {
	flex-grow: 1;
	padding: 12px 30px;
}

.publication-card__date {
	margin-bottom: 12px;
	font-size: 13px;
	font-weight: 500;
}

.publication-card__title {
	margin-bottom: 12px;
	font-size: 20px;
	font-weight: bold;
}

.publication-card__text {
	line-height: 24px;
	font-size: 14px;
}

.publication-card__more {
	display: flex;
	margin: -20px;
	margin-left: 0;
	width: 134px;
	min-width: 134px;
	background-color: #e8e8e9;
	&:before {
		display: block;
		content: '\e801';
		margin: auto;
		width: 72px;
		height: 52px;
		line-height: 52px;
		font-family: "fontello";
		font-size: 24px;
		color: #fff;
		text-align: center;
		background-color: $red;
		transition: transform .3s;
	}
	&:hover {
		// &:before {
		// 	transform: scale(1.1);
		// }
	}
}