.pagination {
	display: flex;
	justify-content: center;
	color: #373737;
}

.pagination__item,
.pagination__prev,
.pagination__next {
	margin: 0 3px;
	width: 38px;
	height: 36px;
	line-height: 36px;
	text-align: center;
	font-weight: 600;
	background-color: #fff;
	box-shadow: 0 5px 29px rgba(0, 0, 0, .13);
	transition: background-color .3s;
	&:hover, &.active {
		color: #fff;
		text-decoration: none;
		background-color: #ea3d56;
	}
}

.pagination__prev,
.pagination__next {
	font-size: 0;
	&:before {
		font-family: "fontello";
		font-size: 14px;
	}
}

.pagination__prev {
	margin-right: 6px;
	&:before {
		margin-right: 2px;
		content: '\e800';
	}
}

.pagination__next {
	margin-left: 6px;
	&:before {
		margin-left: 2px;
		content: '\e801';
	}
}