header {
  position: fixed;
	display: flex;
	align-items: center;
  width: 100%;
	height: 120px;
	color: #fff;
	background-color: transparent;
	z-index: 10;
	transition: background-color .3s, box-shadow .3s;
  &.sticky {
  	background-color: rgba(0, 0, 0, .8);
  	box-shadow: 0 0 4px rgba(0, 0, 0, .2);
  }
}

.contacts-group {
	position: relative;
	padding-left: 42px;
	&:before {
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -17px;
		font-family: "fontello";
		content: '\e802';
		width: 34px;
		height: 34px;
		line-height: 34px;
		font-size: 16px;
		text-align: center;
		border-radius: 50%;
		background-color: $red;
	}
}

.header-contact {
	font-size: 18px;
}

.header-nav {
	display: flex;
	list-style: none;
	margin: 0;
	margin-top: 16px;
	margin-right: -34px;
	padding: 0;
	li {
		margin-right: 34px;
	}
	a {
		transition: color .3s;
		&:hover {
			color: $red;
			// text-decoration: none;
		}
	}
}

// .header__section {
//   flex-grow: 1;
//   display: flex;
//   align-items: center;
//   flex-basis: 50%;
// }

// .burger {
// 	position: relative;
//   width: 34px;
//   height: 34px;
//   border: none;
//   background-color: transparent;
//   span {
// 	  position: relative;
// 	  user-select: none;
// 	  position: absolute;
// 	  top: 50%;
// 	  left: 0;
// 	  margin-top: -2px;
//     &, &:before, &:after {
//       display: block;
//       width: 100%;
//       height: 4px;
//       background-color: #1d1d23;
//     }
//     &:before, &:after {
//       position: absolute;
//       content: '';
//     }
//     &:before {
//       top: -10px;
//     }
//     &:after {
//       top: 10px;
//     }
//   }
// }

// .burger-menu {
//   .header-popup {
//     &:before {
//       left: 9px;
//     }
//   }
// }

// .burger-list {
//   list-style: none;
//   margin: -10px 0;
//   padding: 0;
//   a {
//     position: relative;
//     display: block;
//     padding: 10px 0 10px 15px;
//     transition: color .3s;
//     &:before {
//       content: '';
//       width: 0;
//       height: 0;
//       border: 5px solid transparent;
//       border-right-color: transparent;
//       border-right-style: solid;
//       border-right-width: 5px;
//       border-left-color: transparent;
//       border-left-color: currentColor;
//       border-right: 0;
//       left: 0px;
//       top: 50%;
//       position: absolute;
//       margin-top: -5px;
//     }
//     &:hover {
//       color: #0c743b;
//       text-decoration: none;
//     }
//   }
// }

// .lang-items {
//   margin: 20px -4px -4px;
//   text-align: center;
//   line-height: 0;
// }

// .lang-item {
//   display: inline-block;
//   margin: 4px;
//   width: 30px;
//   height: 20px;
//   border: 1px solid #eee;
//   background: center no-repeat;
//   background-size: cover;
//   transition: border-color .3s;
//   &:hover {
//     border-color: #0c743b;
//   }
// }

// .location {
//   font-size: 15px;
// }

// .location-toggle {
//   cursor: pointer;
//   transition: color .3s;
//   &:hover {
//     color: #0c743b;
//   }
// }

// .login-toggle {
//   vertical-align: top;
//   width: 32px;
//   height: 32px;
//   padding: 0;
//   padding-left: 32px;
//   border: none;
//   background: url(../img/i-login.svg) center no-repeat;
//   &:focus {
//     outline: none;
//   }
// }

// .header-popup {
//   position: absolute;
//   min-width: 220px;
//   margin-top: 14px;
//   padding: 30px;
//   border-radius: 6px;
//   border: none;
//   box-shadow: 0 0 20px rgba(18, 19, 20, .1);
//   .form {
//     min-width: 220px;
//   }
//   &:before {
//     position: absolute;
//     content: '';
//     top: -10px;
//     left: 6px;
//     border-bottom: 10px solid #fff;
//     border-left: 8px solid transparent;
//     border-right: 8px solid transparent;
//   }
// }

// .header-popup__close {
//   position: absolute;
//   top: 10px;
//   right: 10px;
//   width: 20px;
//   height: 20px;
//   font-size: 0;
//   color: #000;
//   cursor: pointer;
//   &:before, &:after {
//     position: absolute;
//     content: '';
//     top: 50%;
//     left: 50%;
//     margin-top: -1px;
//     margin-left: -8px;
//     width: 16px;
//     height: 2px;
//     background-color: currentColor;
//   }
//   &:before {
//     transform: rotate(45deg);
//   }
//   &:after {
//     transform: rotate(-45deg);
//   }
//   &:hover {
//     color: red;
//   }
// }


// .header-contacts {
//   display: flex;
//   align-items: center;
//   padding-left: 48px;
//   background: url(../img/i-phone.svg) left center no-repeat;
//   background-size: 34px;
// }

// .header-phones {
//   font-size: 17px;
//   a {
//     display: block;
//   }
// }


