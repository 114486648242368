footer {
  color: #fff;
  background-color: #27262c;
  // box-shadow: 0 0 16px rgba(18, 19, 20, .11);
  // .logo {
  //   width: 64px;
  //   min-width: 64px;
  // }
  // .text {
  //   font-size: 11px;
  //   color: #969696;
  // }
  // .link {
  //   font-size: 12px;
  //   color: #969696;
  // }
}

.footer__main {
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer__bottom {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  background-color: #232227;
}

.footer-list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 120px;
  font-size: 14px;
  li {
    margin-bottom: 12px;
  }
  a {
    transition: color .3s;
    &:hover {
      color: #ea3d56;
      text-decoration: none;
    }
  }
}

.contact-item {
  position: relative;
  margin-bottom: 8px;
  padding-left: 30px;
  font-size: 16px;
  [class^="icon-"], [class*=" icon-"] {
    position: absolute;
    top: -2px;
    left: 0;
    font-size: 18px;
    color: #ea3d56;
    &:before {
      margin: 0;
    }
  }
}

.btn-social {
  height: 40px;
  padding: 0 18px;
  border-radius: 20px;
  font-size: 13px;
  color: #fff;
  border: none;
  background-color: #6585c2;
}
