@charset "UTF-8";
*,
*::before,
*::after {
  box-sizing: border-box; }

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important; }

hr {
  margin: 4px 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25; }

hr:not([size]) {
  height: 1px; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  margin-top: 0;
  margin-bottom: 2px;
  font-weight: 500;
  line-height: 1.2; }

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw); }
  @media (min-width: 1200px) {
    h1, .h1 {
      font-size: 2.5rem; } }

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw); }
  @media (min-width: 1200px) {
    h2, .h2 {
      font-size: 2rem; } }

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw); }
  @media (min-width: 1200px) {
    h3, .h3 {
      font-size: 1.75rem; } }

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw); }
  @media (min-width: 1200px) {
    h4, .h4 {
      font-size: 1.5rem; } }

h5, .h5 {
  font-size: 1.25rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-top: 0;
  margin-bottom: 1rem; }

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none; }

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit; }

ol,
ul {
  padding-left: 2rem; }

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem; }

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0; }

dt {
  font-weight: 700; }

dd {
  margin-bottom: .5rem;
  margin-left: 0; }

blockquote {
  margin: 0 0 1rem; }

b,
strong {
  font-weight: bolder; }

small, .small {
  font-size: 0.875em; }

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline; }

sub {
  bottom: -.25em; }

sup {
  top: -.5em; }

a {
  color: #0d6efd;
  text-decoration: none; }
  a:hover {
    color: #024dbc;
    text-decoration: underline; }

a:not([href]), a:not([href]):hover {
  color: inherit;
  text-decoration: none; }

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em; }

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em; }
  pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal; }

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word; }
  a > code {
    color: inherit; }

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem; }
  kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 700; }

figure {
  margin: 0 0 1rem; }

img {
  vertical-align: middle; }

svg {
  overflow: hidden;
  vertical-align: middle; }

table {
  border-collapse: collapse; }

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom; }

th {
  text-align: inherit; }

label {
  display: inline-block;
  margin-bottom: 0.5rem; }

button {
  border-radius: 0; }

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
input {
  overflow: visible; }

button,
select {
  text-transform: none; }

select {
  word-wrap: normal; }

[list]::-webkit-calendar-picker-indicator {
  display: none; }

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }
  button:not(:disabled),
  [type="button"]:not(:disabled),
  [type="reset"]:not(:disabled),
  [type="submit"]:not(:disabled) {
    cursor: pointer; }

::-moz-focus-inner {
  padding: 0;
  border-style: none; }

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: textfield; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
  color: inherit;
  white-space: normal; }
  @media (min-width: 1200px) {
    legend {
      font-size: 1.5rem; } }

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3; }

progress {
  vertical-align: baseline; }

::-webkit-datetime-edit {
  overflow: visible;
  line-height: 0; }

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: textfield; }

::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-color-swatch-wrapper {
  padding: 0; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

template {
  display: none; }

main {
  display: block; }

[hidden] {
  display: none !important; }

.lead {
  font-size: 1.25rem;
  font-weight: 300; }

.display-1 {
  font-size: calc(1.725rem + 5.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-1 {
      font-size: 6rem; } }

.display-2 {
  font-size: calc(1.675rem + 5.1vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-2 {
      font-size: 5.5rem; } }

.display-3 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-3 {
      font-size: 4.5rem; } }

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2; }
  @media (min-width: 1200px) {
    .display-4 {
      font-size: 3.5rem; } }

.list-unstyled {
  padding-left: 0;
  list-style: none; }

.list-inline {
  padding-left: 0;
  list-style: none; }

.list-inline-item {
  display: inline-block; }
  .list-inline-item:not(:last-child) {
    margin-right: 0.5rem; }

.initialism {
  font-size: 0.875em;
  text-transform: uppercase; }

.blockquote {
  margin-bottom: 4px;
  font-size: 1.25rem; }

.blockquote-footer {
  display: block;
  font-size: 0.875em;
  color: #6c757d; }
  .blockquote-footer::before {
    content: "\2014\00A0"; }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 540px; } }
  @media (min-width: 768px) {
    .container {
      max-width: 720px; } }
  @media (min-width: 992px) {
    .container {
      max-width: 960px; } }
  @media (min-width: 1200px) {
    .container {
      max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5; }

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem; }

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem; }

.form-control {
  display: block;
  width: 100%;
  min-height: 45px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
  color: #1c1c21;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ea3d56;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out; }
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none; } }
  .form-control::-ms-expand {
    background-color: transparent;
    border: 0; }
  .form-control:focus {
    color: #1c1c21;
    background-color: #fff;
    border-color: #8bbafe;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-control::placeholder {
    color: #818080;
    opacity: 1; }
  .form-control:disabled, .form-control[readonly] {
    background-color: #e9ecef;
    opacity: 1; }

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0; }
  .form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
    padding-right: 0;
    padding-left: 0; }

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.form-control-color {
  max-width: 3rem;
  padding: 0.375rem; }

.form-control-color::-moz-color-swatch {
  border-radius: 0; }

.form-control-color::-webkit-color-swatch {
  border-radius: 0; }

.form-select {
  display: inline-block;
  width: 100%;
  height: 45px;
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: normal;
  line-height: 1.5;
  color: #1c1c21;
  vertical-align: middle;
  background: #fff url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.75rem center/16px 12px;
  border: 1px solid #ea3d56;
  border-radius: 0.25rem;
  appearance: none; }
  .form-select:focus {
    border-color: #8bbafe;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
    .form-select:focus::-ms-value {
      color: #1c1c21;
      background-color: #fff; }
  .form-select[multiple], .form-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 0.75rem;
    background-image: none; }
  .form-select:disabled {
    color: #6c757d;
    background-color: #e9ecef; }
  .form-select::-ms-expand {
    display: none; }
  .form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #1c1c21; }

.form-select-sm {
  height: calc(1.5em + 0.5rem + 2px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem; }

.form-select-lg {
  height: calc(1.5em + 1rem + 2px);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem; }

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.75em;
  margin-bottom: 0.125rem; }
  .form-check .form-check-input {
    float: left;
    margin-left: -1.75em; }

.form-check-input {
  width: 1.25em;
  height: 1.25em;
  margin-top: 0.125em;
  vertical-align: top;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact; }
  .form-check-input[type="checkbox"] {
    border-radius: 0.25em; }
  .form-check-input[type="radio"] {
    border-radius: 50%; }
  .form-check-input:active {
    filter: brightness(90%); }
  .form-check-input:focus {
    border-color: #8bbafe;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-check-input:checked {
    background-color: #0d6efd;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 1em;
    border-color: #0d6efd; }
    .form-check-input:checked[type="checkbox"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M4 8.5L6.5 11l6-6'/%3e%3c/svg%3e"); }
    .form-check-input:checked[type="radio"] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e"); }
  .form-check-input[type="checkbox"]:indeterminate {
    background-color: #0d6efd;
    background-repeat: no-repeat;
    background-position: center center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M5 8h6'/%3e%3c/svg%3e");
    background-size: 1em;
    border-color: #0d6efd; }
  .form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5; }
  .form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
    opacity: .5; }

.form-check-label {
  margin-bottom: 0; }

.form-switch {
  padding-left: 2.5em; }
  .form-switch .form-check-input {
    width: 2em;
    margin-left: -2.5em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;
    border-radius: 2em;
    color-adjust: exact; }
    .form-switch .form-check-input:focus {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2380bdff'/%3e%3c/svg%3e"); }
    .form-switch .form-check-input:checked {
      background-position: right center;
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); }

.form-check-inline {
  display: inline-block;
  margin-right: 1rem; }

.form-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 45px;
  margin-bottom: 0; }

.form-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: 45px;
  margin: 0;
  opacity: 0; }
  .form-file-input:focus ~ .form-file-label {
    border-color: #8bbafe;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-file-input:focus-within ~ .form-file-label {
    border-color: #8bbafe;
    box-shadow: 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-file-input[disabled] ~ .form-file-label .form-file-text,
  .form-file-input:disabled ~ .form-file-label .form-file-text {
    background-color: #e9ecef; }

.form-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  height: 45px;
  border-color: #ea3d56;
  border-radius: 0; }

.form-file-text {
  display: block;
  flex-grow: 1;
  padding: 0.375rem 0.75rem;
  overflow: hidden;
  font-weight: normal;
  line-height: 1.5;
  color: #1c1c21;
  text-overflow: ellipsis;
  white-space: nowrap;
  background-color: #fff;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-left-radius: inherit;
  border-bottom-left-radius: inherit; }

.form-file-button {
  display: block;
  flex-shrink: 0;
  padding: 0.375rem 0.75rem;
  margin-left: -1px;
  line-height: 1.5;
  color: #1c1c21;
  background-color: #e9ecef;
  border-color: inherit;
  border-style: solid;
  border-width: 1px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit; }

.form-range {
  width: 100%;
  height: 1.4rem;
  padding: 0;
  background-color: transparent;
  appearance: none; }
  .form-range:focus {
    outline: none; }
    .form-range:focus::-webkit-slider-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-moz-range-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
    .form-range:focus::-ms-thumb {
      box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(13, 110, 253, 0.25); }
  .form-range::-moz-focus-outer {
    border: 0; }
  .form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -0.25rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-webkit-slider-thumb {
        transition: none; } }
    .form-range::-webkit-slider-thumb:active {
      background-color: #bed8fe; }
  .form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-moz-range-thumb {
        transition: none; } }
    .form-range::-moz-range-thumb:active {
      background-color: #bed8fe; }
  .form-range::-moz-range-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: #dee2e6;
    border-color: transparent;
    border-radius: 1rem; }
  .form-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: 0.2rem;
    margin-left: 0.2rem;
    background-color: #0d6efd;
    border: 0;
    border-radius: 1rem;
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    appearance: none; }
    @media (prefers-reduced-motion: reduce) {
      .form-range::-ms-thumb {
        transition: none; } }
    .form-range::-ms-thumb:active {
      background-color: #bed8fe; }
  .form-range::-ms-track {
    width: 100%;
    height: 0.5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: 0.5rem; }
  .form-range::-ms-fill-lower {
    background-color: #dee2e6;
    border-radius: 1rem; }
  .form-range::-ms-fill-upper {
    margin-right: 15px;
    background-color: #dee2e6;
    border-radius: 1rem; }
  .form-range:disabled {
    pointer-events: none; }
    .form-range:disabled::-webkit-slider-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-moz-range-thumb {
      background-color: #adb5bd; }
    .form-range:disabled::-ms-thumb {
      background-color: #adb5bd; }

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px; }
  .form-row > .col,
  .form-row > [class*="col-"] {
    padding-right: 5px;
    padding-left: 5px; }

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center; }
  .form-inline .form-check {
    width: 100%; }
  @media (min-width: 576px) {
    .form-inline label {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 0; }
    .form-inline .form-control {
      display: inline-block;
      width: auto;
      vertical-align: middle; }
    .form-inline .form-control-plaintext {
      display: inline-block; }
    .form-inline .input-group,
    .form-inline .form-select {
      width: auto; }
    .form-inline .form-check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: auto;
      padding-left: 0; }
    .form-inline .form-check-input {
      position: relative;
      flex-shrink: 0;
      margin-top: 0;
      margin-right: 0.25rem;
      margin-left: 0; } }

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%; }
  .input-group > .form-control,
  .input-group > .form-select,
  .input-group > .form-file {
    position: relative;
    flex: 1 1 0%;
    min-width: 0;
    margin-bottom: 0; }
    .input-group > .form-control + .form-control,
    .input-group > .form-control + .form-select,
    .input-group > .form-control + .form-file,
    .input-group > .form-select + .form-control,
    .input-group > .form-select + .form-select,
    .input-group > .form-select + .form-file,
    .input-group > .form-file + .form-control,
    .input-group > .form-file + .form-select,
    .input-group > .form-file + .form-file {
      margin-left: -1px; }
  .input-group > .form-control:focus,
  .input-group > .form-select:focus,
  .input-group > .form-file .form-file-input:focus ~ .form-file-label {
    z-index: 3; }
  .input-group > .form-file .form-file-input:focus {
    z-index: 4; }
  .input-group > .form-control:not(:last-child),
  .input-group > .form-select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .input-group > .form-control:not(:first-child),
  .input-group > .form-select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .input-group > .form-file {
    display: flex;
    align-items: center; }
    .input-group > .form-file:not(:last-child) .form-file-label {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0; }
    .input-group > .form-file:not(:first-child) .form-file-label {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0; }

.input-group-prepend,
.input-group-append {
  display: flex; }
  .input-group-prepend .btn,
  .input-group-append .btn {
    position: relative;
    z-index: 2; }
    .input-group-prepend .btn:focus,
    .input-group-append .btn:focus {
      z-index: 3; }
  .input-group-prepend .btn + .btn,
  .input-group-prepend .btn + .input-group-text,
  .input-group-prepend .input-group-text + .input-group-text,
  .input-group-prepend .input-group-text + .btn,
  .input-group-append .btn + .btn,
  .input-group-append .btn + .input-group-text,
  .input-group-append .input-group-text + .input-group-text,
  .input-group-append .input-group-text + .btn {
    margin-left: -1px; }

.input-group-prepend {
  margin-right: -1px; }

.input-group-append {
  margin-left: -1px; }

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #1c1c21;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ea3d56;
  border-radius: 0; }

.input-group-lg > .form-control {
  min-height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-select {
  height: calc(1.5em + 1rem + 2px); }

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-prepend > .input-group-text,
.input-group-lg > .input-group-append > .input-group-text,
.input-group-lg > .input-group-prepend > .btn,
.input-group-lg > .input-group-append > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem; }

.input-group-sm > .form-control {
  min-height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-select {
  height: calc(1.5em + 0.5rem + 2px); }

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-prepend > .input-group-text,
.input-group-sm > .input-group-append > .input-group-text,
.input-group-sm > .input-group-prepend > .btn,
.input-group-sm > .input-group-append > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem; }

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 1.75rem; }

.input-group > .input-group-prepend > .btn,
.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .btn,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.input-group > .input-group-append > .btn,
.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .btn,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .btn:not(:first-child),
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #28a745; }

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(40, 167, 69, 0.9);
  border-radius: 0.25rem; }

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block; }

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #28a745;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.75rem center/16px 12px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:valid:focus, .form-select.is-valid:focus {
    border-color: #28a745;
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #28a745; }
  .was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
    background-color: #34ce57; }
  .was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
    box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
    color: #28a745; }

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: .5em; }

.was-validated .form-file-input:valid ~ .form-file-label, .form-file-input.is-valid ~ .form-file-label {
  border-color: #28a745; }

.was-validated .form-file-input:valid:focus ~ .form-file-label, .form-file-input.is-valid:focus ~ .form-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25); }

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #ea3d56; }

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(234, 61, 86, 0.9);
  border-radius: 0.25rem; }

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block; }

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #ea3d56;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea3d56' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea3d56' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
    border-color: #ea3d56;
    box-shadow: 0 0 0 0.2rem rgba(234, 61, 86, 0.25); }

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem); }

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #ea3d56;
  padding-right: calc(0.75em + 2.3125rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e") no-repeat right 0.75rem center/16px 12px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23ea3d56' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ea3d56' stroke='none'/%3e%3c/svg%3e") #fff no-repeat center right 1.75rem/calc(0.75em + 0.375rem) calc(0.75em + 0.375rem); }
  .was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
    border-color: #ea3d56;
    box-shadow: 0 0 0 0.2rem rgba(234, 61, 86, 0.25); }

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #ea3d56; }
  .was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
    background-color: #ef6b7e; }
  .was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
    box-shadow: 0 0 0 0.2rem rgba(234, 61, 86, 0.25); }
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
    color: #ea3d56; }

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: .5em; }

.was-validated .form-file-input:invalid ~ .form-file-label, .form-file-input.is-invalid ~ .form-file-label {
  border-color: #ea3d56; }

.was-validated .form-file-input:invalid:focus ~ .form-file-label, .form-file-input.is-invalid:focus ~ .form-file-label {
  border-color: #ea3d56;
  box-shadow: 0 0 0 0.2rem rgba(234, 61, 86, 0.25); }

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative; }

.dropdown-toggle {
  white-space: nowrap; }
  .dropdown-toggle::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent; }
  .dropdown-toggle:empty::after {
    margin-left: 0; }

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem; }

.dropdown-menu-left {
  right: auto;
  left: 0; }

.dropdown-menu-right {
  right: 0;
  left: auto; }

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0; }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto; } }

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0; }
  .dropdown-menu-md-right {
    right: 0;
    left: auto; } }

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0; }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto; } }

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0; }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto; } }

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem; }

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent; }

.dropup .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem; }

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid; }

.dropright .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropright .dropdown-toggle::after {
  vertical-align: 0; }

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem; }

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: ""; }

.dropleft .dropdown-toggle::after {
  display: none; }

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent; }

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0; }

.dropleft .dropdown-toggle::before {
  vertical-align: 0; }

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto; }

.dropdown-divider {
  height: 0;
  margin: 2px 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef; }

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0; }
  .dropdown-item:hover, .dropdown-item:focus {
    color: #16181b;
    text-decoration: none;
    background-color: #f8f9fa; }
  .dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #0d6efd; }
  .dropdown-item.disabled, .dropdown-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: transparent; }

.dropdown-menu.show {
  display: block; }

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap; }

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #212529; }

.fade {
  transition: opacity 0.15s linear; }
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none; } }
  .fade:not(.show) {
    opacity: 0; }

.collapse:not(.show) {
  display: none; }

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease; }
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none; } }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: flex !important; }

.d-inline-flex {
  display: inline-flex !important; }

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  box-shadow: none !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: sticky !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #0d6efd !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #ea3d56 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.vw-100 {
  width: 100vw !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mh-100 {
  max-height: 100% !important; }

.vh-100 {
  height: 100vh !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.flex-fill {
  flex: 1 1 auto !important; }

.flex-row {
  flex-direction: row !important; }

.flex-column {
  flex-direction: column !important; }

.flex-row-reverse {
  flex-direction: row-reverse !important; }

.flex-column-reverse {
  flex-direction: column-reverse !important; }

.flex-grow-0 {
  flex-grow: 0 !important; }

.flex-grow-1 {
  flex-grow: 1 !important; }

.flex-shrink-0 {
  flex-shrink: 0 !important; }

.flex-shrink-1 {
  flex-shrink: 1 !important; }

.flex-wrap {
  flex-wrap: wrap !important; }

.flex-nowrap {
  flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important; }

.justify-content-start {
  justify-content: flex-start !important; }

.justify-content-end {
  justify-content: flex-end !important; }

.justify-content-center {
  justify-content: center !important; }

.justify-content-between {
  justify-content: space-between !important; }

.justify-content-around {
  justify-content: space-around !important; }

.align-items-start {
  align-items: flex-start !important; }

.align-items-end {
  align-items: flex-end !important; }

.align-items-center {
  align-items: center !important; }

.align-items-baseline {
  align-items: baseline !important; }

.align-items-stretch {
  align-items: stretch !important; }

.align-content-start {
  align-content: flex-start !important; }

.align-content-end {
  align-content: flex-end !important; }

.align-content-center {
  align-content: center !important; }

.align-content-between {
  align-content: space-between !important; }

.align-content-around {
  align-content: space-around !important; }

.align-content-stretch {
  align-content: stretch !important; }

.align-self-auto {
  align-self: auto !important; }

.align-self-start {
  align-self: flex-start !important; }

.align-self-end {
  align-self: flex-end !important; }

.align-self-center {
  align-self: center !important; }

.align-self-baseline {
  align-self: baseline !important; }

.align-self-stretch {
  align-self: stretch !important; }

.order-first {
  order: -1 !important; }

.order-0 {
  order: 0 !important; }

.order-1 {
  order: 1 !important; }

.order-2 {
  order: 2 !important; }

.order-3 {
  order: 3 !important; }

.order-4 {
  order: 4 !important; }

.order-5 {
  order: 5 !important; }

.order-last {
  order: 6 !important; }

.m-0 {
  margin: 0 !important; }

.m-1 {
  margin: 4px !important; }

.m-2 {
  margin: 8px !important; }

.m-3 {
  margin: 12px !important; }

.m-4 {
  margin: 16px !important; }

.m-5 {
  margin: 20px !important; }

.m-6 {
  margin: 30px !important; }

.m-7 {
  margin: 40px !important; }

.m-8 {
  margin: 50px !important; }

.m-9 {
  margin: 60px !important; }

.m-10 {
  margin: 70px !important; }

.m-11 {
  margin: 80px !important; }

.m-12 {
  margin: 90px !important; }

.m-auto {
  margin: auto !important; }

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important; }

.mx-1 {
  margin-right: 4px !important;
  margin-left: 4px !important; }

.mx-2 {
  margin-right: 8px !important;
  margin-left: 8px !important; }

.mx-3 {
  margin-right: 12px !important;
  margin-left: 12px !important; }

.mx-4 {
  margin-right: 16px !important;
  margin-left: 16px !important; }

.mx-5 {
  margin-right: 20px !important;
  margin-left: 20px !important; }

.mx-6 {
  margin-right: 30px !important;
  margin-left: 30px !important; }

.mx-7 {
  margin-right: 40px !important;
  margin-left: 40px !important; }

.mx-8 {
  margin-right: 50px !important;
  margin-left: 50px !important; }

.mx-9 {
  margin-right: 60px !important;
  margin-left: 60px !important; }

.mx-10 {
  margin-right: 70px !important;
  margin-left: 70px !important; }

.mx-11 {
  margin-right: 80px !important;
  margin-left: 80px !important; }

.mx-12 {
  margin-right: 90px !important;
  margin-left: 90px !important; }

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important; }

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.my-1 {
  margin-top: 4px !important;
  margin-bottom: 4px !important; }

.my-2 {
  margin-top: 8px !important;
  margin-bottom: 8px !important; }

.my-3 {
  margin-top: 12px !important;
  margin-bottom: 12px !important; }

.my-4 {
  margin-top: 16px !important;
  margin-bottom: 16px !important; }

.my-5 {
  margin-top: 20px !important;
  margin-bottom: 20px !important; }

.my-6 {
  margin-top: 30px !important;
  margin-bottom: 30px !important; }

.my-7 {
  margin-top: 40px !important;
  margin-bottom: 40px !important; }

.my-8 {
  margin-top: 50px !important;
  margin-bottom: 50px !important; }

.my-9 {
  margin-top: 60px !important;
  margin-bottom: 60px !important; }

.my-10 {
  margin-top: 70px !important;
  margin-bottom: 70px !important; }

.my-11 {
  margin-top: 80px !important;
  margin-bottom: 80px !important; }

.my-12 {
  margin-top: 90px !important;
  margin-bottom: 90px !important; }

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important; }

.mt-0 {
  margin-top: 0 !important; }

.mt-1 {
  margin-top: 4px !important; }

.mt-2 {
  margin-top: 8px !important; }

.mt-3 {
  margin-top: 12px !important; }

.mt-4 {
  margin-top: 16px !important; }

.mt-5 {
  margin-top: 20px !important; }

.mt-6 {
  margin-top: 30px !important; }

.mt-7 {
  margin-top: 40px !important; }

.mt-8 {
  margin-top: 50px !important; }

.mt-9 {
  margin-top: 60px !important; }

.mt-10 {
  margin-top: 70px !important; }

.mt-11 {
  margin-top: 80px !important; }

.mt-12 {
  margin-top: 90px !important; }

.mt-auto {
  margin-top: auto !important; }

.mr-0 {
  margin-right: 0 !important; }

.mr-1 {
  margin-right: 4px !important; }

.mr-2 {
  margin-right: 8px !important; }

.mr-3 {
  margin-right: 12px !important; }

.mr-4 {
  margin-right: 16px !important; }

.mr-5 {
  margin-right: 20px !important; }

.mr-6 {
  margin-right: 30px !important; }

.mr-7 {
  margin-right: 40px !important; }

.mr-8 {
  margin-right: 50px !important; }

.mr-9 {
  margin-right: 60px !important; }

.mr-10 {
  margin-right: 70px !important; }

.mr-11 {
  margin-right: 80px !important; }

.mr-12 {
  margin-right: 90px !important; }

.mr-auto {
  margin-right: auto !important; }

.mb-0 {
  margin-bottom: 0 !important; }

.mb-1 {
  margin-bottom: 4px !important; }

.mb-2 {
  margin-bottom: 8px !important; }

.mb-3 {
  margin-bottom: 12px !important; }

.mb-4 {
  margin-bottom: 16px !important; }

.mb-5 {
  margin-bottom: 20px !important; }

.mb-6 {
  margin-bottom: 30px !important; }

.mb-7 {
  margin-bottom: 40px !important; }

.mb-8 {
  margin-bottom: 50px !important; }

.mb-9 {
  margin-bottom: 60px !important; }

.mb-10 {
  margin-bottom: 70px !important; }

.mb-11 {
  margin-bottom: 80px !important; }

.mb-12 {
  margin-bottom: 90px !important; }

.mb-auto {
  margin-bottom: auto !important; }

.ml-0 {
  margin-left: 0 !important; }

.ml-1 {
  margin-left: 4px !important; }

.ml-2 {
  margin-left: 8px !important; }

.ml-3 {
  margin-left: 12px !important; }

.ml-4 {
  margin-left: 16px !important; }

.ml-5 {
  margin-left: 20px !important; }

.ml-6 {
  margin-left: 30px !important; }

.ml-7 {
  margin-left: 40px !important; }

.ml-8 {
  margin-left: 50px !important; }

.ml-9 {
  margin-left: 60px !important; }

.ml-10 {
  margin-left: 70px !important; }

.ml-11 {
  margin-left: 80px !important; }

.ml-12 {
  margin-left: 90px !important; }

.ml-auto {
  margin-left: auto !important; }

.m-n1 {
  margin: -4px !important; }

.m-n2 {
  margin: -8px !important; }

.m-n3 {
  margin: -12px !important; }

.m-n4 {
  margin: -16px !important; }

.m-n5 {
  margin: -20px !important; }

.m-n6 {
  margin: -30px !important; }

.m-n7 {
  margin: -40px !important; }

.m-n8 {
  margin: -50px !important; }

.m-n9 {
  margin: -60px !important; }

.m-n10 {
  margin: -70px !important; }

.m-n11 {
  margin: -80px !important; }

.m-n12 {
  margin: -90px !important; }

.mx-n1 {
  margin-right: -4px !important;
  margin-left: -4px !important; }

.mx-n2 {
  margin-right: -8px !important;
  margin-left: -8px !important; }

.mx-n3 {
  margin-right: -12px !important;
  margin-left: -12px !important; }

.mx-n4 {
  margin-right: -16px !important;
  margin-left: -16px !important; }

.mx-n5 {
  margin-right: -20px !important;
  margin-left: -20px !important; }

.mx-n6 {
  margin-right: -30px !important;
  margin-left: -30px !important; }

.mx-n7 {
  margin-right: -40px !important;
  margin-left: -40px !important; }

.mx-n8 {
  margin-right: -50px !important;
  margin-left: -50px !important; }

.mx-n9 {
  margin-right: -60px !important;
  margin-left: -60px !important; }

.mx-n10 {
  margin-right: -70px !important;
  margin-left: -70px !important; }

.mx-n11 {
  margin-right: -80px !important;
  margin-left: -80px !important; }

.mx-n12 {
  margin-right: -90px !important;
  margin-left: -90px !important; }

.my-n1 {
  margin-top: -4px !important;
  margin-bottom: -4px !important; }

.my-n2 {
  margin-top: -8px !important;
  margin-bottom: -8px !important; }

.my-n3 {
  margin-top: -12px !important;
  margin-bottom: -12px !important; }

.my-n4 {
  margin-top: -16px !important;
  margin-bottom: -16px !important; }

.my-n5 {
  margin-top: -20px !important;
  margin-bottom: -20px !important; }

.my-n6 {
  margin-top: -30px !important;
  margin-bottom: -30px !important; }

.my-n7 {
  margin-top: -40px !important;
  margin-bottom: -40px !important; }

.my-n8 {
  margin-top: -50px !important;
  margin-bottom: -50px !important; }

.my-n9 {
  margin-top: -60px !important;
  margin-bottom: -60px !important; }

.my-n10 {
  margin-top: -70px !important;
  margin-bottom: -70px !important; }

.my-n11 {
  margin-top: -80px !important;
  margin-bottom: -80px !important; }

.my-n12 {
  margin-top: -90px !important;
  margin-bottom: -90px !important; }

.mt-n1 {
  margin-top: -4px !important; }

.mt-n2 {
  margin-top: -8px !important; }

.mt-n3 {
  margin-top: -12px !important; }

.mt-n4 {
  margin-top: -16px !important; }

.mt-n5 {
  margin-top: -20px !important; }

.mt-n6 {
  margin-top: -30px !important; }

.mt-n7 {
  margin-top: -40px !important; }

.mt-n8 {
  margin-top: -50px !important; }

.mt-n9 {
  margin-top: -60px !important; }

.mt-n10 {
  margin-top: -70px !important; }

.mt-n11 {
  margin-top: -80px !important; }

.mt-n12 {
  margin-top: -90px !important; }

.mr-n1 {
  margin-right: -4px !important; }

.mr-n2 {
  margin-right: -8px !important; }

.mr-n3 {
  margin-right: -12px !important; }

.mr-n4 {
  margin-right: -16px !important; }

.mr-n5 {
  margin-right: -20px !important; }

.mr-n6 {
  margin-right: -30px !important; }

.mr-n7 {
  margin-right: -40px !important; }

.mr-n8 {
  margin-right: -50px !important; }

.mr-n9 {
  margin-right: -60px !important; }

.mr-n10 {
  margin-right: -70px !important; }

.mr-n11 {
  margin-right: -80px !important; }

.mr-n12 {
  margin-right: -90px !important; }

.mb-n1 {
  margin-bottom: -4px !important; }

.mb-n2 {
  margin-bottom: -8px !important; }

.mb-n3 {
  margin-bottom: -12px !important; }

.mb-n4 {
  margin-bottom: -16px !important; }

.mb-n5 {
  margin-bottom: -20px !important; }

.mb-n6 {
  margin-bottom: -30px !important; }

.mb-n7 {
  margin-bottom: -40px !important; }

.mb-n8 {
  margin-bottom: -50px !important; }

.mb-n9 {
  margin-bottom: -60px !important; }

.mb-n10 {
  margin-bottom: -70px !important; }

.mb-n11 {
  margin-bottom: -80px !important; }

.mb-n12 {
  margin-bottom: -90px !important; }

.ml-n1 {
  margin-left: -4px !important; }

.ml-n2 {
  margin-left: -8px !important; }

.ml-n3 {
  margin-left: -12px !important; }

.ml-n4 {
  margin-left: -16px !important; }

.ml-n5 {
  margin-left: -20px !important; }

.ml-n6 {
  margin-left: -30px !important; }

.ml-n7 {
  margin-left: -40px !important; }

.ml-n8 {
  margin-left: -50px !important; }

.ml-n9 {
  margin-left: -60px !important; }

.ml-n10 {
  margin-left: -70px !important; }

.ml-n11 {
  margin-left: -80px !important; }

.ml-n12 {
  margin-left: -90px !important; }

.p-0 {
  padding: 0 !important; }

.p-1 {
  padding: 4px !important; }

.p-2 {
  padding: 8px !important; }

.p-3 {
  padding: 12px !important; }

.p-4 {
  padding: 16px !important; }

.p-5 {
  padding: 20px !important; }

.p-6 {
  padding: 30px !important; }

.p-7 {
  padding: 40px !important; }

.p-8 {
  padding: 50px !important; }

.p-9 {
  padding: 60px !important; }

.p-10 {
  padding: 70px !important; }

.p-11 {
  padding: 80px !important; }

.p-12 {
  padding: 90px !important; }

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important; }

.px-1 {
  padding-right: 4px !important;
  padding-left: 4px !important; }

.px-2 {
  padding-right: 8px !important;
  padding-left: 8px !important; }

.px-3 {
  padding-right: 12px !important;
  padding-left: 12px !important; }

.px-4 {
  padding-right: 16px !important;
  padding-left: 16px !important; }

.px-5 {
  padding-right: 20px !important;
  padding-left: 20px !important; }

.px-6 {
  padding-right: 30px !important;
  padding-left: 30px !important; }

.px-7 {
  padding-right: 40px !important;
  padding-left: 40px !important; }

.px-8 {
  padding-right: 50px !important;
  padding-left: 50px !important; }

.px-9 {
  padding-right: 60px !important;
  padding-left: 60px !important; }

.px-10 {
  padding-right: 70px !important;
  padding-left: 70px !important; }

.px-11 {
  padding-right: 80px !important;
  padding-left: 80px !important; }

.px-12 {
  padding-right: 90px !important;
  padding-left: 90px !important; }

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important; }

.py-1 {
  padding-top: 4px !important;
  padding-bottom: 4px !important; }

.py-2 {
  padding-top: 8px !important;
  padding-bottom: 8px !important; }

.py-3 {
  padding-top: 12px !important;
  padding-bottom: 12px !important; }

.py-4 {
  padding-top: 16px !important;
  padding-bottom: 16px !important; }

.py-5 {
  padding-top: 20px !important;
  padding-bottom: 20px !important; }

.py-6 {
  padding-top: 30px !important;
  padding-bottom: 30px !important; }

.py-7 {
  padding-top: 40px !important;
  padding-bottom: 40px !important; }

.py-8 {
  padding-top: 50px !important;
  padding-bottom: 50px !important; }

.py-9 {
  padding-top: 60px !important;
  padding-bottom: 60px !important; }

.py-10 {
  padding-top: 70px !important;
  padding-bottom: 70px !important; }

.py-11 {
  padding-top: 80px !important;
  padding-bottom: 80px !important; }

.py-12 {
  padding-top: 90px !important;
  padding-bottom: 90px !important; }

.pt-0 {
  padding-top: 0 !important; }

.pt-1 {
  padding-top: 4px !important; }

.pt-2 {
  padding-top: 8px !important; }

.pt-3 {
  padding-top: 12px !important; }

.pt-4 {
  padding-top: 16px !important; }

.pt-5 {
  padding-top: 20px !important; }

.pt-6 {
  padding-top: 30px !important; }

.pt-7 {
  padding-top: 40px !important; }

.pt-8 {
  padding-top: 50px !important; }

.pt-9 {
  padding-top: 60px !important; }

.pt-10 {
  padding-top: 70px !important; }

.pt-11 {
  padding-top: 80px !important; }

.pt-12 {
  padding-top: 90px !important; }

.pr-0 {
  padding-right: 0 !important; }

.pr-1 {
  padding-right: 4px !important; }

.pr-2 {
  padding-right: 8px !important; }

.pr-3 {
  padding-right: 12px !important; }

.pr-4 {
  padding-right: 16px !important; }

.pr-5 {
  padding-right: 20px !important; }

.pr-6 {
  padding-right: 30px !important; }

.pr-7 {
  padding-right: 40px !important; }

.pr-8 {
  padding-right: 50px !important; }

.pr-9 {
  padding-right: 60px !important; }

.pr-10 {
  padding-right: 70px !important; }

.pr-11 {
  padding-right: 80px !important; }

.pr-12 {
  padding-right: 90px !important; }

.pb-0 {
  padding-bottom: 0 !important; }

.pb-1 {
  padding-bottom: 4px !important; }

.pb-2 {
  padding-bottom: 8px !important; }

.pb-3 {
  padding-bottom: 12px !important; }

.pb-4 {
  padding-bottom: 16px !important; }

.pb-5 {
  padding-bottom: 20px !important; }

.pb-6 {
  padding-bottom: 30px !important; }

.pb-7 {
  padding-bottom: 40px !important; }

.pb-8 {
  padding-bottom: 50px !important; }

.pb-9 {
  padding-bottom: 60px !important; }

.pb-10 {
  padding-bottom: 70px !important; }

.pb-11 {
  padding-bottom: 80px !important; }

.pb-12 {
  padding-bottom: 90px !important; }

.pl-0 {
  padding-left: 0 !important; }

.pl-1 {
  padding-left: 4px !important; }

.pl-2 {
  padding-left: 8px !important; }

.pl-3 {
  padding-left: 12px !important; }

.pl-4 {
  padding-left: 16px !important; }

.pl-5 {
  padding-left: 20px !important; }

.pl-6 {
  padding-left: 30px !important; }

.pl-7 {
  padding-left: 40px !important; }

.pl-8 {
  padding-left: 50px !important; }

.pl-9 {
  padding-left: 60px !important; }

.pl-10 {
  padding-left: 70px !important; }

.pl-11 {
  padding-left: 80px !important; }

.pl-12 {
  padding-left: 90px !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

.text-primary {
  color: #0d6efd !important; }

.text-secondary {
  color: #6c757d !important; }

.text-success {
  color: #28a745 !important; }

.text-info {
  color: #17a2b8 !important; }

.text-warning {
  color: #ffc107 !important; }

.text-danger {
  color: #ea3d56 !important; }

.text-light {
  color: #f8f9fa !important; }

.text-dark {
  color: #343a40 !important; }

.text-white {
  color: #fff !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-reset {
  color: inherit !important; }

.lh-1 {
  line-height: 1 !important; }

.lh-sm {
  line-height: 1.25 !important; }

.lh-base {
  line-height: 1.5 !important; }

.lh-lg {
  line-height: 2 !important; }

.bg-primary {
  background-color: #0d6efd !important; }

.bg-secondary {
  background-color: #6c757d !important; }

.bg-success {
  background-color: #28a745 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

.bg-warning {
  background-color: #ffc107 !important; }

.bg-danger {
  background-color: #ea3d56 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

.bg-dark {
  background-color: #343a40 !important; }

.bg-body {
  background-color: #fff !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-decoration-none {
  text-decoration: none !important; }

.text-decoration-underline {
  text-decoration: underline !important; }

.text-decoration-line-through {
  text-decoration: line-through !important; }

.font-italic {
  font-style: italic !important; }

.font-normal {
  font-style: normal !important; }

.text-break {
  word-wrap: break-word !important; }

.font-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; }
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: flex !important; }
  .d-sm-inline-flex {
    display: inline-flex !important; }
  .flex-sm-fill {
    flex: 1 1 auto !important; }
  .flex-sm-row {
    flex-direction: row !important; }
  .flex-sm-column {
    flex-direction: column !important; }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-sm-grow-0 {
    flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-sm-wrap {
    flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-sm-start {
    justify-content: flex-start !important; }
  .justify-content-sm-end {
    justify-content: flex-end !important; }
  .justify-content-sm-center {
    justify-content: center !important; }
  .justify-content-sm-between {
    justify-content: space-between !important; }
  .justify-content-sm-around {
    justify-content: space-around !important; }
  .align-items-sm-start {
    align-items: flex-start !important; }
  .align-items-sm-end {
    align-items: flex-end !important; }
  .align-items-sm-center {
    align-items: center !important; }
  .align-items-sm-baseline {
    align-items: baseline !important; }
  .align-items-sm-stretch {
    align-items: stretch !important; }
  .align-content-sm-start {
    align-content: flex-start !important; }
  .align-content-sm-end {
    align-content: flex-end !important; }
  .align-content-sm-center {
    align-content: center !important; }
  .align-content-sm-between {
    align-content: space-between !important; }
  .align-content-sm-around {
    align-content: space-around !important; }
  .align-content-sm-stretch {
    align-content: stretch !important; }
  .align-self-sm-auto {
    align-self: auto !important; }
  .align-self-sm-start {
    align-self: flex-start !important; }
  .align-self-sm-end {
    align-self: flex-end !important; }
  .align-self-sm-center {
    align-self: center !important; }
  .align-self-sm-baseline {
    align-self: baseline !important; }
  .align-self-sm-stretch {
    align-self: stretch !important; }
  .order-sm-first {
    order: -1 !important; }
  .order-sm-0 {
    order: 0 !important; }
  .order-sm-1 {
    order: 1 !important; }
  .order-sm-2 {
    order: 2 !important; }
  .order-sm-3 {
    order: 3 !important; }
  .order-sm-4 {
    order: 4 !important; }
  .order-sm-5 {
    order: 5 !important; }
  .order-sm-last {
    order: 6 !important; }
  .m-sm-0 {
    margin: 0 !important; }
  .m-sm-1 {
    margin: 4px !important; }
  .m-sm-2 {
    margin: 8px !important; }
  .m-sm-3 {
    margin: 12px !important; }
  .m-sm-4 {
    margin: 16px !important; }
  .m-sm-5 {
    margin: 20px !important; }
  .m-sm-6 {
    margin: 30px !important; }
  .m-sm-7 {
    margin: 40px !important; }
  .m-sm-8 {
    margin: 50px !important; }
  .m-sm-9 {
    margin: 60px !important; }
  .m-sm-10 {
    margin: 70px !important; }
  .m-sm-11 {
    margin: 80px !important; }
  .m-sm-12 {
    margin: 90px !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-sm-1 {
    margin-right: 4px !important;
    margin-left: 4px !important; }
  .mx-sm-2 {
    margin-right: 8px !important;
    margin-left: 8px !important; }
  .mx-sm-3 {
    margin-right: 12px !important;
    margin-left: 12px !important; }
  .mx-sm-4 {
    margin-right: 16px !important;
    margin-left: 16px !important; }
  .mx-sm-5 {
    margin-right: 20px !important;
    margin-left: 20px !important; }
  .mx-sm-6 {
    margin-right: 30px !important;
    margin-left: 30px !important; }
  .mx-sm-7 {
    margin-right: 40px !important;
    margin-left: 40px !important; }
  .mx-sm-8 {
    margin-right: 50px !important;
    margin-left: 50px !important; }
  .mx-sm-9 {
    margin-right: 60px !important;
    margin-left: 60px !important; }
  .mx-sm-10 {
    margin-right: 70px !important;
    margin-left: 70px !important; }
  .mx-sm-11 {
    margin-right: 80px !important;
    margin-left: 80px !important; }
  .mx-sm-12 {
    margin-right: 90px !important;
    margin-left: 90px !important; }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-sm-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .my-sm-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important; }
  .my-sm-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important; }
  .my-sm-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important; }
  .my-sm-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .my-sm-6 {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .my-sm-7 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .my-sm-8 {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .my-sm-9 {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .my-sm-10 {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .my-sm-11 {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .my-sm-12 {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-sm-0 {
    margin-top: 0 !important; }
  .mt-sm-1 {
    margin-top: 4px !important; }
  .mt-sm-2 {
    margin-top: 8px !important; }
  .mt-sm-3 {
    margin-top: 12px !important; }
  .mt-sm-4 {
    margin-top: 16px !important; }
  .mt-sm-5 {
    margin-top: 20px !important; }
  .mt-sm-6 {
    margin-top: 30px !important; }
  .mt-sm-7 {
    margin-top: 40px !important; }
  .mt-sm-8 {
    margin-top: 50px !important; }
  .mt-sm-9 {
    margin-top: 60px !important; }
  .mt-sm-10 {
    margin-top: 70px !important; }
  .mt-sm-11 {
    margin-top: 80px !important; }
  .mt-sm-12 {
    margin-top: 90px !important; }
  .mt-sm-auto {
    margin-top: auto !important; }
  .mr-sm-0 {
    margin-right: 0 !important; }
  .mr-sm-1 {
    margin-right: 4px !important; }
  .mr-sm-2 {
    margin-right: 8px !important; }
  .mr-sm-3 {
    margin-right: 12px !important; }
  .mr-sm-4 {
    margin-right: 16px !important; }
  .mr-sm-5 {
    margin-right: 20px !important; }
  .mr-sm-6 {
    margin-right: 30px !important; }
  .mr-sm-7 {
    margin-right: 40px !important; }
  .mr-sm-8 {
    margin-right: 50px !important; }
  .mr-sm-9 {
    margin-right: 60px !important; }
  .mr-sm-10 {
    margin-right: 70px !important; }
  .mr-sm-11 {
    margin-right: 80px !important; }
  .mr-sm-12 {
    margin-right: 90px !important; }
  .mr-sm-auto {
    margin-right: auto !important; }
  .mb-sm-0 {
    margin-bottom: 0 !important; }
  .mb-sm-1 {
    margin-bottom: 4px !important; }
  .mb-sm-2 {
    margin-bottom: 8px !important; }
  .mb-sm-3 {
    margin-bottom: 12px !important; }
  .mb-sm-4 {
    margin-bottom: 16px !important; }
  .mb-sm-5 {
    margin-bottom: 20px !important; }
  .mb-sm-6 {
    margin-bottom: 30px !important; }
  .mb-sm-7 {
    margin-bottom: 40px !important; }
  .mb-sm-8 {
    margin-bottom: 50px !important; }
  .mb-sm-9 {
    margin-bottom: 60px !important; }
  .mb-sm-10 {
    margin-bottom: 70px !important; }
  .mb-sm-11 {
    margin-bottom: 80px !important; }
  .mb-sm-12 {
    margin-bottom: 90px !important; }
  .mb-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-0 {
    margin-left: 0 !important; }
  .ml-sm-1 {
    margin-left: 4px !important; }
  .ml-sm-2 {
    margin-left: 8px !important; }
  .ml-sm-3 {
    margin-left: 12px !important; }
  .ml-sm-4 {
    margin-left: 16px !important; }
  .ml-sm-5 {
    margin-left: 20px !important; }
  .ml-sm-6 {
    margin-left: 30px !important; }
  .ml-sm-7 {
    margin-left: 40px !important; }
  .ml-sm-8 {
    margin-left: 50px !important; }
  .ml-sm-9 {
    margin-left: 60px !important; }
  .ml-sm-10 {
    margin-left: 70px !important; }
  .ml-sm-11 {
    margin-left: 80px !important; }
  .ml-sm-12 {
    margin-left: 90px !important; }
  .ml-sm-auto {
    margin-left: auto !important; }
  .m-sm-n1 {
    margin: -4px !important; }
  .m-sm-n2 {
    margin: -8px !important; }
  .m-sm-n3 {
    margin: -12px !important; }
  .m-sm-n4 {
    margin: -16px !important; }
  .m-sm-n5 {
    margin: -20px !important; }
  .m-sm-n6 {
    margin: -30px !important; }
  .m-sm-n7 {
    margin: -40px !important; }
  .m-sm-n8 {
    margin: -50px !important; }
  .m-sm-n9 {
    margin: -60px !important; }
  .m-sm-n10 {
    margin: -70px !important; }
  .m-sm-n11 {
    margin: -80px !important; }
  .m-sm-n12 {
    margin: -90px !important; }
  .mx-sm-n1 {
    margin-right: -4px !important;
    margin-left: -4px !important; }
  .mx-sm-n2 {
    margin-right: -8px !important;
    margin-left: -8px !important; }
  .mx-sm-n3 {
    margin-right: -12px !important;
    margin-left: -12px !important; }
  .mx-sm-n4 {
    margin-right: -16px !important;
    margin-left: -16px !important; }
  .mx-sm-n5 {
    margin-right: -20px !important;
    margin-left: -20px !important; }
  .mx-sm-n6 {
    margin-right: -30px !important;
    margin-left: -30px !important; }
  .mx-sm-n7 {
    margin-right: -40px !important;
    margin-left: -40px !important; }
  .mx-sm-n8 {
    margin-right: -50px !important;
    margin-left: -50px !important; }
  .mx-sm-n9 {
    margin-right: -60px !important;
    margin-left: -60px !important; }
  .mx-sm-n10 {
    margin-right: -70px !important;
    margin-left: -70px !important; }
  .mx-sm-n11 {
    margin-right: -80px !important;
    margin-left: -80px !important; }
  .mx-sm-n12 {
    margin-right: -90px !important;
    margin-left: -90px !important; }
  .my-sm-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .my-sm-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important; }
  .my-sm-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important; }
  .my-sm-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important; }
  .my-sm-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .my-sm-n6 {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .my-sm-n7 {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .my-sm-n8 {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .my-sm-n9 {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .my-sm-n10 {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .my-sm-n11 {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .my-sm-n12 {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mt-sm-n1 {
    margin-top: -4px !important; }
  .mt-sm-n2 {
    margin-top: -8px !important; }
  .mt-sm-n3 {
    margin-top: -12px !important; }
  .mt-sm-n4 {
    margin-top: -16px !important; }
  .mt-sm-n5 {
    margin-top: -20px !important; }
  .mt-sm-n6 {
    margin-top: -30px !important; }
  .mt-sm-n7 {
    margin-top: -40px !important; }
  .mt-sm-n8 {
    margin-top: -50px !important; }
  .mt-sm-n9 {
    margin-top: -60px !important; }
  .mt-sm-n10 {
    margin-top: -70px !important; }
  .mt-sm-n11 {
    margin-top: -80px !important; }
  .mt-sm-n12 {
    margin-top: -90px !important; }
  .mr-sm-n1 {
    margin-right: -4px !important; }
  .mr-sm-n2 {
    margin-right: -8px !important; }
  .mr-sm-n3 {
    margin-right: -12px !important; }
  .mr-sm-n4 {
    margin-right: -16px !important; }
  .mr-sm-n5 {
    margin-right: -20px !important; }
  .mr-sm-n6 {
    margin-right: -30px !important; }
  .mr-sm-n7 {
    margin-right: -40px !important; }
  .mr-sm-n8 {
    margin-right: -50px !important; }
  .mr-sm-n9 {
    margin-right: -60px !important; }
  .mr-sm-n10 {
    margin-right: -70px !important; }
  .mr-sm-n11 {
    margin-right: -80px !important; }
  .mr-sm-n12 {
    margin-right: -90px !important; }
  .mb-sm-n1 {
    margin-bottom: -4px !important; }
  .mb-sm-n2 {
    margin-bottom: -8px !important; }
  .mb-sm-n3 {
    margin-bottom: -12px !important; }
  .mb-sm-n4 {
    margin-bottom: -16px !important; }
  .mb-sm-n5 {
    margin-bottom: -20px !important; }
  .mb-sm-n6 {
    margin-bottom: -30px !important; }
  .mb-sm-n7 {
    margin-bottom: -40px !important; }
  .mb-sm-n8 {
    margin-bottom: -50px !important; }
  .mb-sm-n9 {
    margin-bottom: -60px !important; }
  .mb-sm-n10 {
    margin-bottom: -70px !important; }
  .mb-sm-n11 {
    margin-bottom: -80px !important; }
  .mb-sm-n12 {
    margin-bottom: -90px !important; }
  .ml-sm-n1 {
    margin-left: -4px !important; }
  .ml-sm-n2 {
    margin-left: -8px !important; }
  .ml-sm-n3 {
    margin-left: -12px !important; }
  .ml-sm-n4 {
    margin-left: -16px !important; }
  .ml-sm-n5 {
    margin-left: -20px !important; }
  .ml-sm-n6 {
    margin-left: -30px !important; }
  .ml-sm-n7 {
    margin-left: -40px !important; }
  .ml-sm-n8 {
    margin-left: -50px !important; }
  .ml-sm-n9 {
    margin-left: -60px !important; }
  .ml-sm-n10 {
    margin-left: -70px !important; }
  .ml-sm-n11 {
    margin-left: -80px !important; }
  .ml-sm-n12 {
    margin-left: -90px !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .p-sm-1 {
    padding: 4px !important; }
  .p-sm-2 {
    padding: 8px !important; }
  .p-sm-3 {
    padding: 12px !important; }
  .p-sm-4 {
    padding: 16px !important; }
  .p-sm-5 {
    padding: 20px !important; }
  .p-sm-6 {
    padding: 30px !important; }
  .p-sm-7 {
    padding: 40px !important; }
  .p-sm-8 {
    padding: 50px !important; }
  .p-sm-9 {
    padding: 60px !important; }
  .p-sm-10 {
    padding: 70px !important; }
  .p-sm-11 {
    padding: 80px !important; }
  .p-sm-12 {
    padding: 90px !important; }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-sm-1 {
    padding-right: 4px !important;
    padding-left: 4px !important; }
  .px-sm-2 {
    padding-right: 8px !important;
    padding-left: 8px !important; }
  .px-sm-3 {
    padding-right: 12px !important;
    padding-left: 12px !important; }
  .px-sm-4 {
    padding-right: 16px !important;
    padding-left: 16px !important; }
  .px-sm-5 {
    padding-right: 20px !important;
    padding-left: 20px !important; }
  .px-sm-6 {
    padding-right: 30px !important;
    padding-left: 30px !important; }
  .px-sm-7 {
    padding-right: 40px !important;
    padding-left: 40px !important; }
  .px-sm-8 {
    padding-right: 50px !important;
    padding-left: 50px !important; }
  .px-sm-9 {
    padding-right: 60px !important;
    padding-left: 60px !important; }
  .px-sm-10 {
    padding-right: 70px !important;
    padding-left: 70px !important; }
  .px-sm-11 {
    padding-right: 80px !important;
    padding-left: 80px !important; }
  .px-sm-12 {
    padding-right: 90px !important;
    padding-left: 90px !important; }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-sm-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .py-sm-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important; }
  .py-sm-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important; }
  .py-sm-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important; }
  .py-sm-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .py-sm-6 {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .py-sm-7 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .py-sm-8 {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .py-sm-9 {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .py-sm-10 {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .py-sm-11 {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .py-sm-12 {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pt-sm-0 {
    padding-top: 0 !important; }
  .pt-sm-1 {
    padding-top: 4px !important; }
  .pt-sm-2 {
    padding-top: 8px !important; }
  .pt-sm-3 {
    padding-top: 12px !important; }
  .pt-sm-4 {
    padding-top: 16px !important; }
  .pt-sm-5 {
    padding-top: 20px !important; }
  .pt-sm-6 {
    padding-top: 30px !important; }
  .pt-sm-7 {
    padding-top: 40px !important; }
  .pt-sm-8 {
    padding-top: 50px !important; }
  .pt-sm-9 {
    padding-top: 60px !important; }
  .pt-sm-10 {
    padding-top: 70px !important; }
  .pt-sm-11 {
    padding-top: 80px !important; }
  .pt-sm-12 {
    padding-top: 90px !important; }
  .pr-sm-0 {
    padding-right: 0 !important; }
  .pr-sm-1 {
    padding-right: 4px !important; }
  .pr-sm-2 {
    padding-right: 8px !important; }
  .pr-sm-3 {
    padding-right: 12px !important; }
  .pr-sm-4 {
    padding-right: 16px !important; }
  .pr-sm-5 {
    padding-right: 20px !important; }
  .pr-sm-6 {
    padding-right: 30px !important; }
  .pr-sm-7 {
    padding-right: 40px !important; }
  .pr-sm-8 {
    padding-right: 50px !important; }
  .pr-sm-9 {
    padding-right: 60px !important; }
  .pr-sm-10 {
    padding-right: 70px !important; }
  .pr-sm-11 {
    padding-right: 80px !important; }
  .pr-sm-12 {
    padding-right: 90px !important; }
  .pb-sm-0 {
    padding-bottom: 0 !important; }
  .pb-sm-1 {
    padding-bottom: 4px !important; }
  .pb-sm-2 {
    padding-bottom: 8px !important; }
  .pb-sm-3 {
    padding-bottom: 12px !important; }
  .pb-sm-4 {
    padding-bottom: 16px !important; }
  .pb-sm-5 {
    padding-bottom: 20px !important; }
  .pb-sm-6 {
    padding-bottom: 30px !important; }
  .pb-sm-7 {
    padding-bottom: 40px !important; }
  .pb-sm-8 {
    padding-bottom: 50px !important; }
  .pb-sm-9 {
    padding-bottom: 60px !important; }
  .pb-sm-10 {
    padding-bottom: 70px !important; }
  .pb-sm-11 {
    padding-bottom: 80px !important; }
  .pb-sm-12 {
    padding-bottom: 90px !important; }
  .pl-sm-0 {
    padding-left: 0 !important; }
  .pl-sm-1 {
    padding-left: 4px !important; }
  .pl-sm-2 {
    padding-left: 8px !important; }
  .pl-sm-3 {
    padding-left: 12px !important; }
  .pl-sm-4 {
    padding-left: 16px !important; }
  .pl-sm-5 {
    padding-left: 20px !important; }
  .pl-sm-6 {
    padding-left: 30px !important; }
  .pl-sm-7 {
    padding-left: 40px !important; }
  .pl-sm-8 {
    padding-left: 50px !important; }
  .pl-sm-9 {
    padding-left: 60px !important; }
  .pl-sm-10 {
    padding-left: 70px !important; }
  .pl-sm-11 {
    padding-left: 80px !important; }
  .pl-sm-12 {
    padding-left: 90px !important; }
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; }
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: flex !important; }
  .d-md-inline-flex {
    display: inline-flex !important; }
  .flex-md-fill {
    flex: 1 1 auto !important; }
  .flex-md-row {
    flex-direction: row !important; }
  .flex-md-column {
    flex-direction: column !important; }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-md-grow-0 {
    flex-grow: 0 !important; }
  .flex-md-grow-1 {
    flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-md-wrap {
    flex-wrap: wrap !important; }
  .flex-md-nowrap {
    flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-md-start {
    justify-content: flex-start !important; }
  .justify-content-md-end {
    justify-content: flex-end !important; }
  .justify-content-md-center {
    justify-content: center !important; }
  .justify-content-md-between {
    justify-content: space-between !important; }
  .justify-content-md-around {
    justify-content: space-around !important; }
  .align-items-md-start {
    align-items: flex-start !important; }
  .align-items-md-end {
    align-items: flex-end !important; }
  .align-items-md-center {
    align-items: center !important; }
  .align-items-md-baseline {
    align-items: baseline !important; }
  .align-items-md-stretch {
    align-items: stretch !important; }
  .align-content-md-start {
    align-content: flex-start !important; }
  .align-content-md-end {
    align-content: flex-end !important; }
  .align-content-md-center {
    align-content: center !important; }
  .align-content-md-between {
    align-content: space-between !important; }
  .align-content-md-around {
    align-content: space-around !important; }
  .align-content-md-stretch {
    align-content: stretch !important; }
  .align-self-md-auto {
    align-self: auto !important; }
  .align-self-md-start {
    align-self: flex-start !important; }
  .align-self-md-end {
    align-self: flex-end !important; }
  .align-self-md-center {
    align-self: center !important; }
  .align-self-md-baseline {
    align-self: baseline !important; }
  .align-self-md-stretch {
    align-self: stretch !important; }
  .order-md-first {
    order: -1 !important; }
  .order-md-0 {
    order: 0 !important; }
  .order-md-1 {
    order: 1 !important; }
  .order-md-2 {
    order: 2 !important; }
  .order-md-3 {
    order: 3 !important; }
  .order-md-4 {
    order: 4 !important; }
  .order-md-5 {
    order: 5 !important; }
  .order-md-last {
    order: 6 !important; }
  .m-md-0 {
    margin: 0 !important; }
  .m-md-1 {
    margin: 4px !important; }
  .m-md-2 {
    margin: 8px !important; }
  .m-md-3 {
    margin: 12px !important; }
  .m-md-4 {
    margin: 16px !important; }
  .m-md-5 {
    margin: 20px !important; }
  .m-md-6 {
    margin: 30px !important; }
  .m-md-7 {
    margin: 40px !important; }
  .m-md-8 {
    margin: 50px !important; }
  .m-md-9 {
    margin: 60px !important; }
  .m-md-10 {
    margin: 70px !important; }
  .m-md-11 {
    margin: 80px !important; }
  .m-md-12 {
    margin: 90px !important; }
  .m-md-auto {
    margin: auto !important; }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-md-1 {
    margin-right: 4px !important;
    margin-left: 4px !important; }
  .mx-md-2 {
    margin-right: 8px !important;
    margin-left: 8px !important; }
  .mx-md-3 {
    margin-right: 12px !important;
    margin-left: 12px !important; }
  .mx-md-4 {
    margin-right: 16px !important;
    margin-left: 16px !important; }
  .mx-md-5 {
    margin-right: 20px !important;
    margin-left: 20px !important; }
  .mx-md-6 {
    margin-right: 30px !important;
    margin-left: 30px !important; }
  .mx-md-7 {
    margin-right: 40px !important;
    margin-left: 40px !important; }
  .mx-md-8 {
    margin-right: 50px !important;
    margin-left: 50px !important; }
  .mx-md-9 {
    margin-right: 60px !important;
    margin-left: 60px !important; }
  .mx-md-10 {
    margin-right: 70px !important;
    margin-left: 70px !important; }
  .mx-md-11 {
    margin-right: 80px !important;
    margin-left: 80px !important; }
  .mx-md-12 {
    margin-right: 90px !important;
    margin-left: 90px !important; }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-md-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .my-md-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important; }
  .my-md-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important; }
  .my-md-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important; }
  .my-md-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .my-md-6 {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .my-md-7 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .my-md-8 {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .my-md-9 {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .my-md-10 {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .my-md-11 {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .my-md-12 {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-md-0 {
    margin-top: 0 !important; }
  .mt-md-1 {
    margin-top: 4px !important; }
  .mt-md-2 {
    margin-top: 8px !important; }
  .mt-md-3 {
    margin-top: 12px !important; }
  .mt-md-4 {
    margin-top: 16px !important; }
  .mt-md-5 {
    margin-top: 20px !important; }
  .mt-md-6 {
    margin-top: 30px !important; }
  .mt-md-7 {
    margin-top: 40px !important; }
  .mt-md-8 {
    margin-top: 50px !important; }
  .mt-md-9 {
    margin-top: 60px !important; }
  .mt-md-10 {
    margin-top: 70px !important; }
  .mt-md-11 {
    margin-top: 80px !important; }
  .mt-md-12 {
    margin-top: 90px !important; }
  .mt-md-auto {
    margin-top: auto !important; }
  .mr-md-0 {
    margin-right: 0 !important; }
  .mr-md-1 {
    margin-right: 4px !important; }
  .mr-md-2 {
    margin-right: 8px !important; }
  .mr-md-3 {
    margin-right: 12px !important; }
  .mr-md-4 {
    margin-right: 16px !important; }
  .mr-md-5 {
    margin-right: 20px !important; }
  .mr-md-6 {
    margin-right: 30px !important; }
  .mr-md-7 {
    margin-right: 40px !important; }
  .mr-md-8 {
    margin-right: 50px !important; }
  .mr-md-9 {
    margin-right: 60px !important; }
  .mr-md-10 {
    margin-right: 70px !important; }
  .mr-md-11 {
    margin-right: 80px !important; }
  .mr-md-12 {
    margin-right: 90px !important; }
  .mr-md-auto {
    margin-right: auto !important; }
  .mb-md-0 {
    margin-bottom: 0 !important; }
  .mb-md-1 {
    margin-bottom: 4px !important; }
  .mb-md-2 {
    margin-bottom: 8px !important; }
  .mb-md-3 {
    margin-bottom: 12px !important; }
  .mb-md-4 {
    margin-bottom: 16px !important; }
  .mb-md-5 {
    margin-bottom: 20px !important; }
  .mb-md-6 {
    margin-bottom: 30px !important; }
  .mb-md-7 {
    margin-bottom: 40px !important; }
  .mb-md-8 {
    margin-bottom: 50px !important; }
  .mb-md-9 {
    margin-bottom: 60px !important; }
  .mb-md-10 {
    margin-bottom: 70px !important; }
  .mb-md-11 {
    margin-bottom: 80px !important; }
  .mb-md-12 {
    margin-bottom: 90px !important; }
  .mb-md-auto {
    margin-bottom: auto !important; }
  .ml-md-0 {
    margin-left: 0 !important; }
  .ml-md-1 {
    margin-left: 4px !important; }
  .ml-md-2 {
    margin-left: 8px !important; }
  .ml-md-3 {
    margin-left: 12px !important; }
  .ml-md-4 {
    margin-left: 16px !important; }
  .ml-md-5 {
    margin-left: 20px !important; }
  .ml-md-6 {
    margin-left: 30px !important; }
  .ml-md-7 {
    margin-left: 40px !important; }
  .ml-md-8 {
    margin-left: 50px !important; }
  .ml-md-9 {
    margin-left: 60px !important; }
  .ml-md-10 {
    margin-left: 70px !important; }
  .ml-md-11 {
    margin-left: 80px !important; }
  .ml-md-12 {
    margin-left: 90px !important; }
  .ml-md-auto {
    margin-left: auto !important; }
  .m-md-n1 {
    margin: -4px !important; }
  .m-md-n2 {
    margin: -8px !important; }
  .m-md-n3 {
    margin: -12px !important; }
  .m-md-n4 {
    margin: -16px !important; }
  .m-md-n5 {
    margin: -20px !important; }
  .m-md-n6 {
    margin: -30px !important; }
  .m-md-n7 {
    margin: -40px !important; }
  .m-md-n8 {
    margin: -50px !important; }
  .m-md-n9 {
    margin: -60px !important; }
  .m-md-n10 {
    margin: -70px !important; }
  .m-md-n11 {
    margin: -80px !important; }
  .m-md-n12 {
    margin: -90px !important; }
  .mx-md-n1 {
    margin-right: -4px !important;
    margin-left: -4px !important; }
  .mx-md-n2 {
    margin-right: -8px !important;
    margin-left: -8px !important; }
  .mx-md-n3 {
    margin-right: -12px !important;
    margin-left: -12px !important; }
  .mx-md-n4 {
    margin-right: -16px !important;
    margin-left: -16px !important; }
  .mx-md-n5 {
    margin-right: -20px !important;
    margin-left: -20px !important; }
  .mx-md-n6 {
    margin-right: -30px !important;
    margin-left: -30px !important; }
  .mx-md-n7 {
    margin-right: -40px !important;
    margin-left: -40px !important; }
  .mx-md-n8 {
    margin-right: -50px !important;
    margin-left: -50px !important; }
  .mx-md-n9 {
    margin-right: -60px !important;
    margin-left: -60px !important; }
  .mx-md-n10 {
    margin-right: -70px !important;
    margin-left: -70px !important; }
  .mx-md-n11 {
    margin-right: -80px !important;
    margin-left: -80px !important; }
  .mx-md-n12 {
    margin-right: -90px !important;
    margin-left: -90px !important; }
  .my-md-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .my-md-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important; }
  .my-md-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important; }
  .my-md-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important; }
  .my-md-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .my-md-n6 {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .my-md-n7 {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .my-md-n8 {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .my-md-n9 {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .my-md-n10 {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .my-md-n11 {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .my-md-n12 {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mt-md-n1 {
    margin-top: -4px !important; }
  .mt-md-n2 {
    margin-top: -8px !important; }
  .mt-md-n3 {
    margin-top: -12px !important; }
  .mt-md-n4 {
    margin-top: -16px !important; }
  .mt-md-n5 {
    margin-top: -20px !important; }
  .mt-md-n6 {
    margin-top: -30px !important; }
  .mt-md-n7 {
    margin-top: -40px !important; }
  .mt-md-n8 {
    margin-top: -50px !important; }
  .mt-md-n9 {
    margin-top: -60px !important; }
  .mt-md-n10 {
    margin-top: -70px !important; }
  .mt-md-n11 {
    margin-top: -80px !important; }
  .mt-md-n12 {
    margin-top: -90px !important; }
  .mr-md-n1 {
    margin-right: -4px !important; }
  .mr-md-n2 {
    margin-right: -8px !important; }
  .mr-md-n3 {
    margin-right: -12px !important; }
  .mr-md-n4 {
    margin-right: -16px !important; }
  .mr-md-n5 {
    margin-right: -20px !important; }
  .mr-md-n6 {
    margin-right: -30px !important; }
  .mr-md-n7 {
    margin-right: -40px !important; }
  .mr-md-n8 {
    margin-right: -50px !important; }
  .mr-md-n9 {
    margin-right: -60px !important; }
  .mr-md-n10 {
    margin-right: -70px !important; }
  .mr-md-n11 {
    margin-right: -80px !important; }
  .mr-md-n12 {
    margin-right: -90px !important; }
  .mb-md-n1 {
    margin-bottom: -4px !important; }
  .mb-md-n2 {
    margin-bottom: -8px !important; }
  .mb-md-n3 {
    margin-bottom: -12px !important; }
  .mb-md-n4 {
    margin-bottom: -16px !important; }
  .mb-md-n5 {
    margin-bottom: -20px !important; }
  .mb-md-n6 {
    margin-bottom: -30px !important; }
  .mb-md-n7 {
    margin-bottom: -40px !important; }
  .mb-md-n8 {
    margin-bottom: -50px !important; }
  .mb-md-n9 {
    margin-bottom: -60px !important; }
  .mb-md-n10 {
    margin-bottom: -70px !important; }
  .mb-md-n11 {
    margin-bottom: -80px !important; }
  .mb-md-n12 {
    margin-bottom: -90px !important; }
  .ml-md-n1 {
    margin-left: -4px !important; }
  .ml-md-n2 {
    margin-left: -8px !important; }
  .ml-md-n3 {
    margin-left: -12px !important; }
  .ml-md-n4 {
    margin-left: -16px !important; }
  .ml-md-n5 {
    margin-left: -20px !important; }
  .ml-md-n6 {
    margin-left: -30px !important; }
  .ml-md-n7 {
    margin-left: -40px !important; }
  .ml-md-n8 {
    margin-left: -50px !important; }
  .ml-md-n9 {
    margin-left: -60px !important; }
  .ml-md-n10 {
    margin-left: -70px !important; }
  .ml-md-n11 {
    margin-left: -80px !important; }
  .ml-md-n12 {
    margin-left: -90px !important; }
  .p-md-0 {
    padding: 0 !important; }
  .p-md-1 {
    padding: 4px !important; }
  .p-md-2 {
    padding: 8px !important; }
  .p-md-3 {
    padding: 12px !important; }
  .p-md-4 {
    padding: 16px !important; }
  .p-md-5 {
    padding: 20px !important; }
  .p-md-6 {
    padding: 30px !important; }
  .p-md-7 {
    padding: 40px !important; }
  .p-md-8 {
    padding: 50px !important; }
  .p-md-9 {
    padding: 60px !important; }
  .p-md-10 {
    padding: 70px !important; }
  .p-md-11 {
    padding: 80px !important; }
  .p-md-12 {
    padding: 90px !important; }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-md-1 {
    padding-right: 4px !important;
    padding-left: 4px !important; }
  .px-md-2 {
    padding-right: 8px !important;
    padding-left: 8px !important; }
  .px-md-3 {
    padding-right: 12px !important;
    padding-left: 12px !important; }
  .px-md-4 {
    padding-right: 16px !important;
    padding-left: 16px !important; }
  .px-md-5 {
    padding-right: 20px !important;
    padding-left: 20px !important; }
  .px-md-6 {
    padding-right: 30px !important;
    padding-left: 30px !important; }
  .px-md-7 {
    padding-right: 40px !important;
    padding-left: 40px !important; }
  .px-md-8 {
    padding-right: 50px !important;
    padding-left: 50px !important; }
  .px-md-9 {
    padding-right: 60px !important;
    padding-left: 60px !important; }
  .px-md-10 {
    padding-right: 70px !important;
    padding-left: 70px !important; }
  .px-md-11 {
    padding-right: 80px !important;
    padding-left: 80px !important; }
  .px-md-12 {
    padding-right: 90px !important;
    padding-left: 90px !important; }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-md-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .py-md-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important; }
  .py-md-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important; }
  .py-md-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important; }
  .py-md-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .py-md-6 {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .py-md-7 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .py-md-8 {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .py-md-9 {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .py-md-10 {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .py-md-11 {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .py-md-12 {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pt-md-0 {
    padding-top: 0 !important; }
  .pt-md-1 {
    padding-top: 4px !important; }
  .pt-md-2 {
    padding-top: 8px !important; }
  .pt-md-3 {
    padding-top: 12px !important; }
  .pt-md-4 {
    padding-top: 16px !important; }
  .pt-md-5 {
    padding-top: 20px !important; }
  .pt-md-6 {
    padding-top: 30px !important; }
  .pt-md-7 {
    padding-top: 40px !important; }
  .pt-md-8 {
    padding-top: 50px !important; }
  .pt-md-9 {
    padding-top: 60px !important; }
  .pt-md-10 {
    padding-top: 70px !important; }
  .pt-md-11 {
    padding-top: 80px !important; }
  .pt-md-12 {
    padding-top: 90px !important; }
  .pr-md-0 {
    padding-right: 0 !important; }
  .pr-md-1 {
    padding-right: 4px !important; }
  .pr-md-2 {
    padding-right: 8px !important; }
  .pr-md-3 {
    padding-right: 12px !important; }
  .pr-md-4 {
    padding-right: 16px !important; }
  .pr-md-5 {
    padding-right: 20px !important; }
  .pr-md-6 {
    padding-right: 30px !important; }
  .pr-md-7 {
    padding-right: 40px !important; }
  .pr-md-8 {
    padding-right: 50px !important; }
  .pr-md-9 {
    padding-right: 60px !important; }
  .pr-md-10 {
    padding-right: 70px !important; }
  .pr-md-11 {
    padding-right: 80px !important; }
  .pr-md-12 {
    padding-right: 90px !important; }
  .pb-md-0 {
    padding-bottom: 0 !important; }
  .pb-md-1 {
    padding-bottom: 4px !important; }
  .pb-md-2 {
    padding-bottom: 8px !important; }
  .pb-md-3 {
    padding-bottom: 12px !important; }
  .pb-md-4 {
    padding-bottom: 16px !important; }
  .pb-md-5 {
    padding-bottom: 20px !important; }
  .pb-md-6 {
    padding-bottom: 30px !important; }
  .pb-md-7 {
    padding-bottom: 40px !important; }
  .pb-md-8 {
    padding-bottom: 50px !important; }
  .pb-md-9 {
    padding-bottom: 60px !important; }
  .pb-md-10 {
    padding-bottom: 70px !important; }
  .pb-md-11 {
    padding-bottom: 80px !important; }
  .pb-md-12 {
    padding-bottom: 90px !important; }
  .pl-md-0 {
    padding-left: 0 !important; }
  .pl-md-1 {
    padding-left: 4px !important; }
  .pl-md-2 {
    padding-left: 8px !important; }
  .pl-md-3 {
    padding-left: 12px !important; }
  .pl-md-4 {
    padding-left: 16px !important; }
  .pl-md-5 {
    padding-left: 20px !important; }
  .pl-md-6 {
    padding-left: 30px !important; }
  .pl-md-7 {
    padding-left: 40px !important; }
  .pl-md-8 {
    padding-left: 50px !important; }
  .pl-md-9 {
    padding-left: 60px !important; }
  .pl-md-10 {
    padding-left: 70px !important; }
  .pl-md-11 {
    padding-left: 80px !important; }
  .pl-md-12 {
    padding-left: 90px !important; }
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; }
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: flex !important; }
  .d-lg-inline-flex {
    display: inline-flex !important; }
  .flex-lg-fill {
    flex: 1 1 auto !important; }
  .flex-lg-row {
    flex-direction: row !important; }
  .flex-lg-column {
    flex-direction: column !important; }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-lg-grow-0 {
    flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-lg-wrap {
    flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-lg-start {
    justify-content: flex-start !important; }
  .justify-content-lg-end {
    justify-content: flex-end !important; }
  .justify-content-lg-center {
    justify-content: center !important; }
  .justify-content-lg-between {
    justify-content: space-between !important; }
  .justify-content-lg-around {
    justify-content: space-around !important; }
  .align-items-lg-start {
    align-items: flex-start !important; }
  .align-items-lg-end {
    align-items: flex-end !important; }
  .align-items-lg-center {
    align-items: center !important; }
  .align-items-lg-baseline {
    align-items: baseline !important; }
  .align-items-lg-stretch {
    align-items: stretch !important; }
  .align-content-lg-start {
    align-content: flex-start !important; }
  .align-content-lg-end {
    align-content: flex-end !important; }
  .align-content-lg-center {
    align-content: center !important; }
  .align-content-lg-between {
    align-content: space-between !important; }
  .align-content-lg-around {
    align-content: space-around !important; }
  .align-content-lg-stretch {
    align-content: stretch !important; }
  .align-self-lg-auto {
    align-self: auto !important; }
  .align-self-lg-start {
    align-self: flex-start !important; }
  .align-self-lg-end {
    align-self: flex-end !important; }
  .align-self-lg-center {
    align-self: center !important; }
  .align-self-lg-baseline {
    align-self: baseline !important; }
  .align-self-lg-stretch {
    align-self: stretch !important; }
  .order-lg-first {
    order: -1 !important; }
  .order-lg-0 {
    order: 0 !important; }
  .order-lg-1 {
    order: 1 !important; }
  .order-lg-2 {
    order: 2 !important; }
  .order-lg-3 {
    order: 3 !important; }
  .order-lg-4 {
    order: 4 !important; }
  .order-lg-5 {
    order: 5 !important; }
  .order-lg-last {
    order: 6 !important; }
  .m-lg-0 {
    margin: 0 !important; }
  .m-lg-1 {
    margin: 4px !important; }
  .m-lg-2 {
    margin: 8px !important; }
  .m-lg-3 {
    margin: 12px !important; }
  .m-lg-4 {
    margin: 16px !important; }
  .m-lg-5 {
    margin: 20px !important; }
  .m-lg-6 {
    margin: 30px !important; }
  .m-lg-7 {
    margin: 40px !important; }
  .m-lg-8 {
    margin: 50px !important; }
  .m-lg-9 {
    margin: 60px !important; }
  .m-lg-10 {
    margin: 70px !important; }
  .m-lg-11 {
    margin: 80px !important; }
  .m-lg-12 {
    margin: 90px !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-lg-1 {
    margin-right: 4px !important;
    margin-left: 4px !important; }
  .mx-lg-2 {
    margin-right: 8px !important;
    margin-left: 8px !important; }
  .mx-lg-3 {
    margin-right: 12px !important;
    margin-left: 12px !important; }
  .mx-lg-4 {
    margin-right: 16px !important;
    margin-left: 16px !important; }
  .mx-lg-5 {
    margin-right: 20px !important;
    margin-left: 20px !important; }
  .mx-lg-6 {
    margin-right: 30px !important;
    margin-left: 30px !important; }
  .mx-lg-7 {
    margin-right: 40px !important;
    margin-left: 40px !important; }
  .mx-lg-8 {
    margin-right: 50px !important;
    margin-left: 50px !important; }
  .mx-lg-9 {
    margin-right: 60px !important;
    margin-left: 60px !important; }
  .mx-lg-10 {
    margin-right: 70px !important;
    margin-left: 70px !important; }
  .mx-lg-11 {
    margin-right: 80px !important;
    margin-left: 80px !important; }
  .mx-lg-12 {
    margin-right: 90px !important;
    margin-left: 90px !important; }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-lg-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .my-lg-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important; }
  .my-lg-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important; }
  .my-lg-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important; }
  .my-lg-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .my-lg-6 {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .my-lg-7 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .my-lg-8 {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .my-lg-9 {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .my-lg-10 {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .my-lg-11 {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .my-lg-12 {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-lg-0 {
    margin-top: 0 !important; }
  .mt-lg-1 {
    margin-top: 4px !important; }
  .mt-lg-2 {
    margin-top: 8px !important; }
  .mt-lg-3 {
    margin-top: 12px !important; }
  .mt-lg-4 {
    margin-top: 16px !important; }
  .mt-lg-5 {
    margin-top: 20px !important; }
  .mt-lg-6 {
    margin-top: 30px !important; }
  .mt-lg-7 {
    margin-top: 40px !important; }
  .mt-lg-8 {
    margin-top: 50px !important; }
  .mt-lg-9 {
    margin-top: 60px !important; }
  .mt-lg-10 {
    margin-top: 70px !important; }
  .mt-lg-11 {
    margin-top: 80px !important; }
  .mt-lg-12 {
    margin-top: 90px !important; }
  .mt-lg-auto {
    margin-top: auto !important; }
  .mr-lg-0 {
    margin-right: 0 !important; }
  .mr-lg-1 {
    margin-right: 4px !important; }
  .mr-lg-2 {
    margin-right: 8px !important; }
  .mr-lg-3 {
    margin-right: 12px !important; }
  .mr-lg-4 {
    margin-right: 16px !important; }
  .mr-lg-5 {
    margin-right: 20px !important; }
  .mr-lg-6 {
    margin-right: 30px !important; }
  .mr-lg-7 {
    margin-right: 40px !important; }
  .mr-lg-8 {
    margin-right: 50px !important; }
  .mr-lg-9 {
    margin-right: 60px !important; }
  .mr-lg-10 {
    margin-right: 70px !important; }
  .mr-lg-11 {
    margin-right: 80px !important; }
  .mr-lg-12 {
    margin-right: 90px !important; }
  .mr-lg-auto {
    margin-right: auto !important; }
  .mb-lg-0 {
    margin-bottom: 0 !important; }
  .mb-lg-1 {
    margin-bottom: 4px !important; }
  .mb-lg-2 {
    margin-bottom: 8px !important; }
  .mb-lg-3 {
    margin-bottom: 12px !important; }
  .mb-lg-4 {
    margin-bottom: 16px !important; }
  .mb-lg-5 {
    margin-bottom: 20px !important; }
  .mb-lg-6 {
    margin-bottom: 30px !important; }
  .mb-lg-7 {
    margin-bottom: 40px !important; }
  .mb-lg-8 {
    margin-bottom: 50px !important; }
  .mb-lg-9 {
    margin-bottom: 60px !important; }
  .mb-lg-10 {
    margin-bottom: 70px !important; }
  .mb-lg-11 {
    margin-bottom: 80px !important; }
  .mb-lg-12 {
    margin-bottom: 90px !important; }
  .mb-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-0 {
    margin-left: 0 !important; }
  .ml-lg-1 {
    margin-left: 4px !important; }
  .ml-lg-2 {
    margin-left: 8px !important; }
  .ml-lg-3 {
    margin-left: 12px !important; }
  .ml-lg-4 {
    margin-left: 16px !important; }
  .ml-lg-5 {
    margin-left: 20px !important; }
  .ml-lg-6 {
    margin-left: 30px !important; }
  .ml-lg-7 {
    margin-left: 40px !important; }
  .ml-lg-8 {
    margin-left: 50px !important; }
  .ml-lg-9 {
    margin-left: 60px !important; }
  .ml-lg-10 {
    margin-left: 70px !important; }
  .ml-lg-11 {
    margin-left: 80px !important; }
  .ml-lg-12 {
    margin-left: 90px !important; }
  .ml-lg-auto {
    margin-left: auto !important; }
  .m-lg-n1 {
    margin: -4px !important; }
  .m-lg-n2 {
    margin: -8px !important; }
  .m-lg-n3 {
    margin: -12px !important; }
  .m-lg-n4 {
    margin: -16px !important; }
  .m-lg-n5 {
    margin: -20px !important; }
  .m-lg-n6 {
    margin: -30px !important; }
  .m-lg-n7 {
    margin: -40px !important; }
  .m-lg-n8 {
    margin: -50px !important; }
  .m-lg-n9 {
    margin: -60px !important; }
  .m-lg-n10 {
    margin: -70px !important; }
  .m-lg-n11 {
    margin: -80px !important; }
  .m-lg-n12 {
    margin: -90px !important; }
  .mx-lg-n1 {
    margin-right: -4px !important;
    margin-left: -4px !important; }
  .mx-lg-n2 {
    margin-right: -8px !important;
    margin-left: -8px !important; }
  .mx-lg-n3 {
    margin-right: -12px !important;
    margin-left: -12px !important; }
  .mx-lg-n4 {
    margin-right: -16px !important;
    margin-left: -16px !important; }
  .mx-lg-n5 {
    margin-right: -20px !important;
    margin-left: -20px !important; }
  .mx-lg-n6 {
    margin-right: -30px !important;
    margin-left: -30px !important; }
  .mx-lg-n7 {
    margin-right: -40px !important;
    margin-left: -40px !important; }
  .mx-lg-n8 {
    margin-right: -50px !important;
    margin-left: -50px !important; }
  .mx-lg-n9 {
    margin-right: -60px !important;
    margin-left: -60px !important; }
  .mx-lg-n10 {
    margin-right: -70px !important;
    margin-left: -70px !important; }
  .mx-lg-n11 {
    margin-right: -80px !important;
    margin-left: -80px !important; }
  .mx-lg-n12 {
    margin-right: -90px !important;
    margin-left: -90px !important; }
  .my-lg-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .my-lg-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important; }
  .my-lg-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important; }
  .my-lg-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important; }
  .my-lg-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .my-lg-n6 {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .my-lg-n7 {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .my-lg-n8 {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .my-lg-n9 {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .my-lg-n10 {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .my-lg-n11 {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .my-lg-n12 {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mt-lg-n1 {
    margin-top: -4px !important; }
  .mt-lg-n2 {
    margin-top: -8px !important; }
  .mt-lg-n3 {
    margin-top: -12px !important; }
  .mt-lg-n4 {
    margin-top: -16px !important; }
  .mt-lg-n5 {
    margin-top: -20px !important; }
  .mt-lg-n6 {
    margin-top: -30px !important; }
  .mt-lg-n7 {
    margin-top: -40px !important; }
  .mt-lg-n8 {
    margin-top: -50px !important; }
  .mt-lg-n9 {
    margin-top: -60px !important; }
  .mt-lg-n10 {
    margin-top: -70px !important; }
  .mt-lg-n11 {
    margin-top: -80px !important; }
  .mt-lg-n12 {
    margin-top: -90px !important; }
  .mr-lg-n1 {
    margin-right: -4px !important; }
  .mr-lg-n2 {
    margin-right: -8px !important; }
  .mr-lg-n3 {
    margin-right: -12px !important; }
  .mr-lg-n4 {
    margin-right: -16px !important; }
  .mr-lg-n5 {
    margin-right: -20px !important; }
  .mr-lg-n6 {
    margin-right: -30px !important; }
  .mr-lg-n7 {
    margin-right: -40px !important; }
  .mr-lg-n8 {
    margin-right: -50px !important; }
  .mr-lg-n9 {
    margin-right: -60px !important; }
  .mr-lg-n10 {
    margin-right: -70px !important; }
  .mr-lg-n11 {
    margin-right: -80px !important; }
  .mr-lg-n12 {
    margin-right: -90px !important; }
  .mb-lg-n1 {
    margin-bottom: -4px !important; }
  .mb-lg-n2 {
    margin-bottom: -8px !important; }
  .mb-lg-n3 {
    margin-bottom: -12px !important; }
  .mb-lg-n4 {
    margin-bottom: -16px !important; }
  .mb-lg-n5 {
    margin-bottom: -20px !important; }
  .mb-lg-n6 {
    margin-bottom: -30px !important; }
  .mb-lg-n7 {
    margin-bottom: -40px !important; }
  .mb-lg-n8 {
    margin-bottom: -50px !important; }
  .mb-lg-n9 {
    margin-bottom: -60px !important; }
  .mb-lg-n10 {
    margin-bottom: -70px !important; }
  .mb-lg-n11 {
    margin-bottom: -80px !important; }
  .mb-lg-n12 {
    margin-bottom: -90px !important; }
  .ml-lg-n1 {
    margin-left: -4px !important; }
  .ml-lg-n2 {
    margin-left: -8px !important; }
  .ml-lg-n3 {
    margin-left: -12px !important; }
  .ml-lg-n4 {
    margin-left: -16px !important; }
  .ml-lg-n5 {
    margin-left: -20px !important; }
  .ml-lg-n6 {
    margin-left: -30px !important; }
  .ml-lg-n7 {
    margin-left: -40px !important; }
  .ml-lg-n8 {
    margin-left: -50px !important; }
  .ml-lg-n9 {
    margin-left: -60px !important; }
  .ml-lg-n10 {
    margin-left: -70px !important; }
  .ml-lg-n11 {
    margin-left: -80px !important; }
  .ml-lg-n12 {
    margin-left: -90px !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .p-lg-1 {
    padding: 4px !important; }
  .p-lg-2 {
    padding: 8px !important; }
  .p-lg-3 {
    padding: 12px !important; }
  .p-lg-4 {
    padding: 16px !important; }
  .p-lg-5 {
    padding: 20px !important; }
  .p-lg-6 {
    padding: 30px !important; }
  .p-lg-7 {
    padding: 40px !important; }
  .p-lg-8 {
    padding: 50px !important; }
  .p-lg-9 {
    padding: 60px !important; }
  .p-lg-10 {
    padding: 70px !important; }
  .p-lg-11 {
    padding: 80px !important; }
  .p-lg-12 {
    padding: 90px !important; }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-lg-1 {
    padding-right: 4px !important;
    padding-left: 4px !important; }
  .px-lg-2 {
    padding-right: 8px !important;
    padding-left: 8px !important; }
  .px-lg-3 {
    padding-right: 12px !important;
    padding-left: 12px !important; }
  .px-lg-4 {
    padding-right: 16px !important;
    padding-left: 16px !important; }
  .px-lg-5 {
    padding-right: 20px !important;
    padding-left: 20px !important; }
  .px-lg-6 {
    padding-right: 30px !important;
    padding-left: 30px !important; }
  .px-lg-7 {
    padding-right: 40px !important;
    padding-left: 40px !important; }
  .px-lg-8 {
    padding-right: 50px !important;
    padding-left: 50px !important; }
  .px-lg-9 {
    padding-right: 60px !important;
    padding-left: 60px !important; }
  .px-lg-10 {
    padding-right: 70px !important;
    padding-left: 70px !important; }
  .px-lg-11 {
    padding-right: 80px !important;
    padding-left: 80px !important; }
  .px-lg-12 {
    padding-right: 90px !important;
    padding-left: 90px !important; }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-lg-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .py-lg-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important; }
  .py-lg-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important; }
  .py-lg-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important; }
  .py-lg-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .py-lg-6 {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .py-lg-7 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .py-lg-8 {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .py-lg-9 {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .py-lg-10 {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .py-lg-11 {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .py-lg-12 {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pt-lg-0 {
    padding-top: 0 !important; }
  .pt-lg-1 {
    padding-top: 4px !important; }
  .pt-lg-2 {
    padding-top: 8px !important; }
  .pt-lg-3 {
    padding-top: 12px !important; }
  .pt-lg-4 {
    padding-top: 16px !important; }
  .pt-lg-5 {
    padding-top: 20px !important; }
  .pt-lg-6 {
    padding-top: 30px !important; }
  .pt-lg-7 {
    padding-top: 40px !important; }
  .pt-lg-8 {
    padding-top: 50px !important; }
  .pt-lg-9 {
    padding-top: 60px !important; }
  .pt-lg-10 {
    padding-top: 70px !important; }
  .pt-lg-11 {
    padding-top: 80px !important; }
  .pt-lg-12 {
    padding-top: 90px !important; }
  .pr-lg-0 {
    padding-right: 0 !important; }
  .pr-lg-1 {
    padding-right: 4px !important; }
  .pr-lg-2 {
    padding-right: 8px !important; }
  .pr-lg-3 {
    padding-right: 12px !important; }
  .pr-lg-4 {
    padding-right: 16px !important; }
  .pr-lg-5 {
    padding-right: 20px !important; }
  .pr-lg-6 {
    padding-right: 30px !important; }
  .pr-lg-7 {
    padding-right: 40px !important; }
  .pr-lg-8 {
    padding-right: 50px !important; }
  .pr-lg-9 {
    padding-right: 60px !important; }
  .pr-lg-10 {
    padding-right: 70px !important; }
  .pr-lg-11 {
    padding-right: 80px !important; }
  .pr-lg-12 {
    padding-right: 90px !important; }
  .pb-lg-0 {
    padding-bottom: 0 !important; }
  .pb-lg-1 {
    padding-bottom: 4px !important; }
  .pb-lg-2 {
    padding-bottom: 8px !important; }
  .pb-lg-3 {
    padding-bottom: 12px !important; }
  .pb-lg-4 {
    padding-bottom: 16px !important; }
  .pb-lg-5 {
    padding-bottom: 20px !important; }
  .pb-lg-6 {
    padding-bottom: 30px !important; }
  .pb-lg-7 {
    padding-bottom: 40px !important; }
  .pb-lg-8 {
    padding-bottom: 50px !important; }
  .pb-lg-9 {
    padding-bottom: 60px !important; }
  .pb-lg-10 {
    padding-bottom: 70px !important; }
  .pb-lg-11 {
    padding-bottom: 80px !important; }
  .pb-lg-12 {
    padding-bottom: 90px !important; }
  .pl-lg-0 {
    padding-left: 0 !important; }
  .pl-lg-1 {
    padding-left: 4px !important; }
  .pl-lg-2 {
    padding-left: 8px !important; }
  .pl-lg-3 {
    padding-left: 12px !important; }
  .pl-lg-4 {
    padding-left: 16px !important; }
  .pl-lg-5 {
    padding-left: 20px !important; }
  .pl-lg-6 {
    padding-left: 30px !important; }
  .pl-lg-7 {
    padding-left: 40px !important; }
  .pl-lg-8 {
    padding-left: 50px !important; }
  .pl-lg-9 {
    padding-left: 60px !important; }
  .pl-lg-10 {
    padding-left: 70px !important; }
  .pl-lg-11 {
    padding-left: 80px !important; }
  .pl-lg-12 {
    padding-left: 90px !important; }
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; }
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: flex !important; }
  .d-xl-inline-flex {
    display: inline-flex !important; }
  .flex-xl-fill {
    flex: 1 1 auto !important; }
  .flex-xl-row {
    flex-direction: row !important; }
  .flex-xl-column {
    flex-direction: column !important; }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important; }
  .flex-xl-grow-0 {
    flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important; }
  .flex-xl-wrap {
    flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important; }
  .justify-content-xl-start {
    justify-content: flex-start !important; }
  .justify-content-xl-end {
    justify-content: flex-end !important; }
  .justify-content-xl-center {
    justify-content: center !important; }
  .justify-content-xl-between {
    justify-content: space-between !important; }
  .justify-content-xl-around {
    justify-content: space-around !important; }
  .align-items-xl-start {
    align-items: flex-start !important; }
  .align-items-xl-end {
    align-items: flex-end !important; }
  .align-items-xl-center {
    align-items: center !important; }
  .align-items-xl-baseline {
    align-items: baseline !important; }
  .align-items-xl-stretch {
    align-items: stretch !important; }
  .align-content-xl-start {
    align-content: flex-start !important; }
  .align-content-xl-end {
    align-content: flex-end !important; }
  .align-content-xl-center {
    align-content: center !important; }
  .align-content-xl-between {
    align-content: space-between !important; }
  .align-content-xl-around {
    align-content: space-around !important; }
  .align-content-xl-stretch {
    align-content: stretch !important; }
  .align-self-xl-auto {
    align-self: auto !important; }
  .align-self-xl-start {
    align-self: flex-start !important; }
  .align-self-xl-end {
    align-self: flex-end !important; }
  .align-self-xl-center {
    align-self: center !important; }
  .align-self-xl-baseline {
    align-self: baseline !important; }
  .align-self-xl-stretch {
    align-self: stretch !important; }
  .order-xl-first {
    order: -1 !important; }
  .order-xl-0 {
    order: 0 !important; }
  .order-xl-1 {
    order: 1 !important; }
  .order-xl-2 {
    order: 2 !important; }
  .order-xl-3 {
    order: 3 !important; }
  .order-xl-4 {
    order: 4 !important; }
  .order-xl-5 {
    order: 5 !important; }
  .order-xl-last {
    order: 6 !important; }
  .m-xl-0 {
    margin: 0 !important; }
  .m-xl-1 {
    margin: 4px !important; }
  .m-xl-2 {
    margin: 8px !important; }
  .m-xl-3 {
    margin: 12px !important; }
  .m-xl-4 {
    margin: 16px !important; }
  .m-xl-5 {
    margin: 20px !important; }
  .m-xl-6 {
    margin: 30px !important; }
  .m-xl-7 {
    margin: 40px !important; }
  .m-xl-8 {
    margin: 50px !important; }
  .m-xl-9 {
    margin: 60px !important; }
  .m-xl-10 {
    margin: 70px !important; }
  .m-xl-11 {
    margin: 80px !important; }
  .m-xl-12 {
    margin: 90px !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important; }
  .mx-xl-1 {
    margin-right: 4px !important;
    margin-left: 4px !important; }
  .mx-xl-2 {
    margin-right: 8px !important;
    margin-left: 8px !important; }
  .mx-xl-3 {
    margin-right: 12px !important;
    margin-left: 12px !important; }
  .mx-xl-4 {
    margin-right: 16px !important;
    margin-left: 16px !important; }
  .mx-xl-5 {
    margin-right: 20px !important;
    margin-left: 20px !important; }
  .mx-xl-6 {
    margin-right: 30px !important;
    margin-left: 30px !important; }
  .mx-xl-7 {
    margin-right: 40px !important;
    margin-left: 40px !important; }
  .mx-xl-8 {
    margin-right: 50px !important;
    margin-left: 50px !important; }
  .mx-xl-9 {
    margin-right: 60px !important;
    margin-left: 60px !important; }
  .mx-xl-10 {
    margin-right: 70px !important;
    margin-left: 70px !important; }
  .mx-xl-11 {
    margin-right: 80px !important;
    margin-left: 80px !important; }
  .mx-xl-12 {
    margin-right: 90px !important;
    margin-left: 90px !important; }
  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important; }
  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important; }
  .my-xl-1 {
    margin-top: 4px !important;
    margin-bottom: 4px !important; }
  .my-xl-2 {
    margin-top: 8px !important;
    margin-bottom: 8px !important; }
  .my-xl-3 {
    margin-top: 12px !important;
    margin-bottom: 12px !important; }
  .my-xl-4 {
    margin-top: 16px !important;
    margin-bottom: 16px !important; }
  .my-xl-5 {
    margin-top: 20px !important;
    margin-bottom: 20px !important; }
  .my-xl-6 {
    margin-top: 30px !important;
    margin-bottom: 30px !important; }
  .my-xl-7 {
    margin-top: 40px !important;
    margin-bottom: 40px !important; }
  .my-xl-8 {
    margin-top: 50px !important;
    margin-bottom: 50px !important; }
  .my-xl-9 {
    margin-top: 60px !important;
    margin-bottom: 60px !important; }
  .my-xl-10 {
    margin-top: 70px !important;
    margin-bottom: 70px !important; }
  .my-xl-11 {
    margin-top: 80px !important;
    margin-bottom: 80px !important; }
  .my-xl-12 {
    margin-top: 90px !important;
    margin-bottom: 90px !important; }
  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important; }
  .mt-xl-0 {
    margin-top: 0 !important; }
  .mt-xl-1 {
    margin-top: 4px !important; }
  .mt-xl-2 {
    margin-top: 8px !important; }
  .mt-xl-3 {
    margin-top: 12px !important; }
  .mt-xl-4 {
    margin-top: 16px !important; }
  .mt-xl-5 {
    margin-top: 20px !important; }
  .mt-xl-6 {
    margin-top: 30px !important; }
  .mt-xl-7 {
    margin-top: 40px !important; }
  .mt-xl-8 {
    margin-top: 50px !important; }
  .mt-xl-9 {
    margin-top: 60px !important; }
  .mt-xl-10 {
    margin-top: 70px !important; }
  .mt-xl-11 {
    margin-top: 80px !important; }
  .mt-xl-12 {
    margin-top: 90px !important; }
  .mt-xl-auto {
    margin-top: auto !important; }
  .mr-xl-0 {
    margin-right: 0 !important; }
  .mr-xl-1 {
    margin-right: 4px !important; }
  .mr-xl-2 {
    margin-right: 8px !important; }
  .mr-xl-3 {
    margin-right: 12px !important; }
  .mr-xl-4 {
    margin-right: 16px !important; }
  .mr-xl-5 {
    margin-right: 20px !important; }
  .mr-xl-6 {
    margin-right: 30px !important; }
  .mr-xl-7 {
    margin-right: 40px !important; }
  .mr-xl-8 {
    margin-right: 50px !important; }
  .mr-xl-9 {
    margin-right: 60px !important; }
  .mr-xl-10 {
    margin-right: 70px !important; }
  .mr-xl-11 {
    margin-right: 80px !important; }
  .mr-xl-12 {
    margin-right: 90px !important; }
  .mr-xl-auto {
    margin-right: auto !important; }
  .mb-xl-0 {
    margin-bottom: 0 !important; }
  .mb-xl-1 {
    margin-bottom: 4px !important; }
  .mb-xl-2 {
    margin-bottom: 8px !important; }
  .mb-xl-3 {
    margin-bottom: 12px !important; }
  .mb-xl-4 {
    margin-bottom: 16px !important; }
  .mb-xl-5 {
    margin-bottom: 20px !important; }
  .mb-xl-6 {
    margin-bottom: 30px !important; }
  .mb-xl-7 {
    margin-bottom: 40px !important; }
  .mb-xl-8 {
    margin-bottom: 50px !important; }
  .mb-xl-9 {
    margin-bottom: 60px !important; }
  .mb-xl-10 {
    margin-bottom: 70px !important; }
  .mb-xl-11 {
    margin-bottom: 80px !important; }
  .mb-xl-12 {
    margin-bottom: 90px !important; }
  .mb-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-0 {
    margin-left: 0 !important; }
  .ml-xl-1 {
    margin-left: 4px !important; }
  .ml-xl-2 {
    margin-left: 8px !important; }
  .ml-xl-3 {
    margin-left: 12px !important; }
  .ml-xl-4 {
    margin-left: 16px !important; }
  .ml-xl-5 {
    margin-left: 20px !important; }
  .ml-xl-6 {
    margin-left: 30px !important; }
  .ml-xl-7 {
    margin-left: 40px !important; }
  .ml-xl-8 {
    margin-left: 50px !important; }
  .ml-xl-9 {
    margin-left: 60px !important; }
  .ml-xl-10 {
    margin-left: 70px !important; }
  .ml-xl-11 {
    margin-left: 80px !important; }
  .ml-xl-12 {
    margin-left: 90px !important; }
  .ml-xl-auto {
    margin-left: auto !important; }
  .m-xl-n1 {
    margin: -4px !important; }
  .m-xl-n2 {
    margin: -8px !important; }
  .m-xl-n3 {
    margin: -12px !important; }
  .m-xl-n4 {
    margin: -16px !important; }
  .m-xl-n5 {
    margin: -20px !important; }
  .m-xl-n6 {
    margin: -30px !important; }
  .m-xl-n7 {
    margin: -40px !important; }
  .m-xl-n8 {
    margin: -50px !important; }
  .m-xl-n9 {
    margin: -60px !important; }
  .m-xl-n10 {
    margin: -70px !important; }
  .m-xl-n11 {
    margin: -80px !important; }
  .m-xl-n12 {
    margin: -90px !important; }
  .mx-xl-n1 {
    margin-right: -4px !important;
    margin-left: -4px !important; }
  .mx-xl-n2 {
    margin-right: -8px !important;
    margin-left: -8px !important; }
  .mx-xl-n3 {
    margin-right: -12px !important;
    margin-left: -12px !important; }
  .mx-xl-n4 {
    margin-right: -16px !important;
    margin-left: -16px !important; }
  .mx-xl-n5 {
    margin-right: -20px !important;
    margin-left: -20px !important; }
  .mx-xl-n6 {
    margin-right: -30px !important;
    margin-left: -30px !important; }
  .mx-xl-n7 {
    margin-right: -40px !important;
    margin-left: -40px !important; }
  .mx-xl-n8 {
    margin-right: -50px !important;
    margin-left: -50px !important; }
  .mx-xl-n9 {
    margin-right: -60px !important;
    margin-left: -60px !important; }
  .mx-xl-n10 {
    margin-right: -70px !important;
    margin-left: -70px !important; }
  .mx-xl-n11 {
    margin-right: -80px !important;
    margin-left: -80px !important; }
  .mx-xl-n12 {
    margin-right: -90px !important;
    margin-left: -90px !important; }
  .my-xl-n1 {
    margin-top: -4px !important;
    margin-bottom: -4px !important; }
  .my-xl-n2 {
    margin-top: -8px !important;
    margin-bottom: -8px !important; }
  .my-xl-n3 {
    margin-top: -12px !important;
    margin-bottom: -12px !important; }
  .my-xl-n4 {
    margin-top: -16px !important;
    margin-bottom: -16px !important; }
  .my-xl-n5 {
    margin-top: -20px !important;
    margin-bottom: -20px !important; }
  .my-xl-n6 {
    margin-top: -30px !important;
    margin-bottom: -30px !important; }
  .my-xl-n7 {
    margin-top: -40px !important;
    margin-bottom: -40px !important; }
  .my-xl-n8 {
    margin-top: -50px !important;
    margin-bottom: -50px !important; }
  .my-xl-n9 {
    margin-top: -60px !important;
    margin-bottom: -60px !important; }
  .my-xl-n10 {
    margin-top: -70px !important;
    margin-bottom: -70px !important; }
  .my-xl-n11 {
    margin-top: -80px !important;
    margin-bottom: -80px !important; }
  .my-xl-n12 {
    margin-top: -90px !important;
    margin-bottom: -90px !important; }
  .mt-xl-n1 {
    margin-top: -4px !important; }
  .mt-xl-n2 {
    margin-top: -8px !important; }
  .mt-xl-n3 {
    margin-top: -12px !important; }
  .mt-xl-n4 {
    margin-top: -16px !important; }
  .mt-xl-n5 {
    margin-top: -20px !important; }
  .mt-xl-n6 {
    margin-top: -30px !important; }
  .mt-xl-n7 {
    margin-top: -40px !important; }
  .mt-xl-n8 {
    margin-top: -50px !important; }
  .mt-xl-n9 {
    margin-top: -60px !important; }
  .mt-xl-n10 {
    margin-top: -70px !important; }
  .mt-xl-n11 {
    margin-top: -80px !important; }
  .mt-xl-n12 {
    margin-top: -90px !important; }
  .mr-xl-n1 {
    margin-right: -4px !important; }
  .mr-xl-n2 {
    margin-right: -8px !important; }
  .mr-xl-n3 {
    margin-right: -12px !important; }
  .mr-xl-n4 {
    margin-right: -16px !important; }
  .mr-xl-n5 {
    margin-right: -20px !important; }
  .mr-xl-n6 {
    margin-right: -30px !important; }
  .mr-xl-n7 {
    margin-right: -40px !important; }
  .mr-xl-n8 {
    margin-right: -50px !important; }
  .mr-xl-n9 {
    margin-right: -60px !important; }
  .mr-xl-n10 {
    margin-right: -70px !important; }
  .mr-xl-n11 {
    margin-right: -80px !important; }
  .mr-xl-n12 {
    margin-right: -90px !important; }
  .mb-xl-n1 {
    margin-bottom: -4px !important; }
  .mb-xl-n2 {
    margin-bottom: -8px !important; }
  .mb-xl-n3 {
    margin-bottom: -12px !important; }
  .mb-xl-n4 {
    margin-bottom: -16px !important; }
  .mb-xl-n5 {
    margin-bottom: -20px !important; }
  .mb-xl-n6 {
    margin-bottom: -30px !important; }
  .mb-xl-n7 {
    margin-bottom: -40px !important; }
  .mb-xl-n8 {
    margin-bottom: -50px !important; }
  .mb-xl-n9 {
    margin-bottom: -60px !important; }
  .mb-xl-n10 {
    margin-bottom: -70px !important; }
  .mb-xl-n11 {
    margin-bottom: -80px !important; }
  .mb-xl-n12 {
    margin-bottom: -90px !important; }
  .ml-xl-n1 {
    margin-left: -4px !important; }
  .ml-xl-n2 {
    margin-left: -8px !important; }
  .ml-xl-n3 {
    margin-left: -12px !important; }
  .ml-xl-n4 {
    margin-left: -16px !important; }
  .ml-xl-n5 {
    margin-left: -20px !important; }
  .ml-xl-n6 {
    margin-left: -30px !important; }
  .ml-xl-n7 {
    margin-left: -40px !important; }
  .ml-xl-n8 {
    margin-left: -50px !important; }
  .ml-xl-n9 {
    margin-left: -60px !important; }
  .ml-xl-n10 {
    margin-left: -70px !important; }
  .ml-xl-n11 {
    margin-left: -80px !important; }
  .ml-xl-n12 {
    margin-left: -90px !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .p-xl-1 {
    padding: 4px !important; }
  .p-xl-2 {
    padding: 8px !important; }
  .p-xl-3 {
    padding: 12px !important; }
  .p-xl-4 {
    padding: 16px !important; }
  .p-xl-5 {
    padding: 20px !important; }
  .p-xl-6 {
    padding: 30px !important; }
  .p-xl-7 {
    padding: 40px !important; }
  .p-xl-8 {
    padding: 50px !important; }
  .p-xl-9 {
    padding: 60px !important; }
  .p-xl-10 {
    padding: 70px !important; }
  .p-xl-11 {
    padding: 80px !important; }
  .p-xl-12 {
    padding: 90px !important; }
  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important; }
  .px-xl-1 {
    padding-right: 4px !important;
    padding-left: 4px !important; }
  .px-xl-2 {
    padding-right: 8px !important;
    padding-left: 8px !important; }
  .px-xl-3 {
    padding-right: 12px !important;
    padding-left: 12px !important; }
  .px-xl-4 {
    padding-right: 16px !important;
    padding-left: 16px !important; }
  .px-xl-5 {
    padding-right: 20px !important;
    padding-left: 20px !important; }
  .px-xl-6 {
    padding-right: 30px !important;
    padding-left: 30px !important; }
  .px-xl-7 {
    padding-right: 40px !important;
    padding-left: 40px !important; }
  .px-xl-8 {
    padding-right: 50px !important;
    padding-left: 50px !important; }
  .px-xl-9 {
    padding-right: 60px !important;
    padding-left: 60px !important; }
  .px-xl-10 {
    padding-right: 70px !important;
    padding-left: 70px !important; }
  .px-xl-11 {
    padding-right: 80px !important;
    padding-left: 80px !important; }
  .px-xl-12 {
    padding-right: 90px !important;
    padding-left: 90px !important; }
  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important; }
  .py-xl-1 {
    padding-top: 4px !important;
    padding-bottom: 4px !important; }
  .py-xl-2 {
    padding-top: 8px !important;
    padding-bottom: 8px !important; }
  .py-xl-3 {
    padding-top: 12px !important;
    padding-bottom: 12px !important; }
  .py-xl-4 {
    padding-top: 16px !important;
    padding-bottom: 16px !important; }
  .py-xl-5 {
    padding-top: 20px !important;
    padding-bottom: 20px !important; }
  .py-xl-6 {
    padding-top: 30px !important;
    padding-bottom: 30px !important; }
  .py-xl-7 {
    padding-top: 40px !important;
    padding-bottom: 40px !important; }
  .py-xl-8 {
    padding-top: 50px !important;
    padding-bottom: 50px !important; }
  .py-xl-9 {
    padding-top: 60px !important;
    padding-bottom: 60px !important; }
  .py-xl-10 {
    padding-top: 70px !important;
    padding-bottom: 70px !important; }
  .py-xl-11 {
    padding-top: 80px !important;
    padding-bottom: 80px !important; }
  .py-xl-12 {
    padding-top: 90px !important;
    padding-bottom: 90px !important; }
  .pt-xl-0 {
    padding-top: 0 !important; }
  .pt-xl-1 {
    padding-top: 4px !important; }
  .pt-xl-2 {
    padding-top: 8px !important; }
  .pt-xl-3 {
    padding-top: 12px !important; }
  .pt-xl-4 {
    padding-top: 16px !important; }
  .pt-xl-5 {
    padding-top: 20px !important; }
  .pt-xl-6 {
    padding-top: 30px !important; }
  .pt-xl-7 {
    padding-top: 40px !important; }
  .pt-xl-8 {
    padding-top: 50px !important; }
  .pt-xl-9 {
    padding-top: 60px !important; }
  .pt-xl-10 {
    padding-top: 70px !important; }
  .pt-xl-11 {
    padding-top: 80px !important; }
  .pt-xl-12 {
    padding-top: 90px !important; }
  .pr-xl-0 {
    padding-right: 0 !important; }
  .pr-xl-1 {
    padding-right: 4px !important; }
  .pr-xl-2 {
    padding-right: 8px !important; }
  .pr-xl-3 {
    padding-right: 12px !important; }
  .pr-xl-4 {
    padding-right: 16px !important; }
  .pr-xl-5 {
    padding-right: 20px !important; }
  .pr-xl-6 {
    padding-right: 30px !important; }
  .pr-xl-7 {
    padding-right: 40px !important; }
  .pr-xl-8 {
    padding-right: 50px !important; }
  .pr-xl-9 {
    padding-right: 60px !important; }
  .pr-xl-10 {
    padding-right: 70px !important; }
  .pr-xl-11 {
    padding-right: 80px !important; }
  .pr-xl-12 {
    padding-right: 90px !important; }
  .pb-xl-0 {
    padding-bottom: 0 !important; }
  .pb-xl-1 {
    padding-bottom: 4px !important; }
  .pb-xl-2 {
    padding-bottom: 8px !important; }
  .pb-xl-3 {
    padding-bottom: 12px !important; }
  .pb-xl-4 {
    padding-bottom: 16px !important; }
  .pb-xl-5 {
    padding-bottom: 20px !important; }
  .pb-xl-6 {
    padding-bottom: 30px !important; }
  .pb-xl-7 {
    padding-bottom: 40px !important; }
  .pb-xl-8 {
    padding-bottom: 50px !important; }
  .pb-xl-9 {
    padding-bottom: 60px !important; }
  .pb-xl-10 {
    padding-bottom: 70px !important; }
  .pb-xl-11 {
    padding-bottom: 80px !important; }
  .pb-xl-12 {
    padding-bottom: 90px !important; }
  .pl-xl-0 {
    padding-left: 0 !important; }
  .pl-xl-1 {
    padding-left: 4px !important; }
  .pl-xl-2 {
    padding-left: 8px !important; }
  .pl-xl-3 {
    padding-left: 12px !important; }
  .pl-xl-4 {
    padding-left: 16px !important; }
  .pl-xl-5 {
    padding-left: 20px !important; }
  .pl-xl-6 {
    padding-left: 30px !important; }
  .pl-xl-7 {
    padding-left: 40px !important; }
  .pl-xl-8 {
    padding-left: 50px !important; }
  .pl-xl-9 {
    padding-left: 60px !important; }
  .pl-xl-10 {
    padding-left: 70px !important; }
  .pl-xl-11 {
    padding-left: 80px !important; }
  .pl-xl-12 {
    padding-left: 90px !important; }
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: flex !important; }
  .d-print-inline-flex {
    display: inline-flex !important; } }

@font-face {
  font-family: 'fontello';
  src: url("../font/fontello.eot?84486055");
  src: url("../font/fontello.eot?84486055#iefix") format("embedded-opentype"), url("../font/fontello.woff2?84486055") format("woff2"), url("../font/fontello.woff?84486055") format("woff"), url("../font/fontello.ttf?84486055") format("truetype"), url("../font/fontello.svg?84486055#fontello") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?84486055#fontello') format('svg');
  }
}
*/
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-arrow-left:before {
  content: '\e800'; }

/* '' */
.icon-arrow-right:before {
  content: '\e801'; }

/* '' */
.icon-call:before {
  content: '\e802'; }

/* '' */
.icon-clock:before {
  content: '\e803'; }

/* '' */
.icon-mail:before {
  content: '\e804'; }

/* '' */
.icon-mark:before {
  content: '\e805'; }

/* '' */
.icon-user:before {
  content: '\e807'; }

/* '' */
.icon-view-360:before {
  content: '\e808'; }

/* '' */
.icon-search:before {
  content: '\e809'; }

/* '' */
.icon-angle-left:before {
  content: '\e80a'; }

/* '' */
.icon-angle-right:before {
  content: '\e80b'; }

/* '' */
body.compensate-for-scrollbar {
  overflow: hidden; }

.fancybox-active {
  height: auto; }

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden; }

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992; }

.fancybox-container * {
  box-sizing: border-box; }

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0; }

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto; }

.fancybox-bg {
  background: #1e1e1e;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71); }

.fancybox-is-open .fancybox-bg {
  opacity: .9;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1); }

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997; }

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible; }

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.fancybox-toolbar {
  right: 0;
  top: 0; }

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994; }

.fancybox-is-open .fancybox-stage {
  overflow: hidden; }

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994; }

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0; }

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block; }

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0; }

.fancybox-slide--image::before {
  display: none; }

.fancybox-slide--html {
  padding: 6px; }

.fancybox-content {
  background: #fff;
  display: inline-block;
  margin: 0;
  max-width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 44px;
  position: relative;
  text-align: left;
  vertical-align: middle; }

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995; }

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out; }

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in; }

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: -webkit-grab;
  cursor: grab; }

.fancybox-is-grabbing .fancybox-content {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

.fancybox-container [data-selectable='true'] {
  cursor: text; }

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%; }

.fancybox-spaceball {
  z-index: 1; }

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%; }

.fancybox-slide--video .fancybox-content {
  background: #000; }

.fancybox-slide--map .fancybox-content {
  background: #e5e3df; }

.fancybox-slide--iframe .fancybox-content {
  background: #fff; }

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%; }

/* Fix iOS */
.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0; }

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%; }

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0; }

/* Buttons */
.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px; }

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc; }

.fancybox-button:hover {
  color: #fff; }

.fancybox-button:focus {
  outline: none; }

.fancybox-button.fancybox-focus {
  outline: 1px dotted; }

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none; }

/* Fix IE11 */
.fancybox-button div {
  height: 100%; }

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%; }

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0; }

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none; }

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none; }

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998; }

/* Close button on the top right corner of html content */
.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401; }

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1; }

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 0;
  top: 0; }

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden; }

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none; }

/* Navigation arrows */
.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px; }

.fancybox-navigation .fancybox-button div {
  padding: 7px; }

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  left: env(safe-area-inset-left);
  padding: 31px 26px 31px 6px; }

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
  right: env(safe-area-inset-right); }

/* Caption */
.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996; }

/*@supports (padding: max(0px)) {
    .fancybox-caption {
        padding: 75px max(44px, env(safe-area-inset-right)) max(25px, env(safe-area-inset-bottom)) max(44px, env(safe-area-inset-left));
    }
}*/
.fancybox-caption--separate {
  margin-top: -50px; }

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all; }

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none; }

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline; }

/* Loading indicator */
.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999; }

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg); } }

/* Transition effects */
.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1); }

/* transitionEffect: slide */
.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0); }

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0); }

/* transitionEffect: fade */
.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1; }

/* transitionEffect: zoom-in-out */
.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5); }

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1); }

/* transitionEffect: rotate */
.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg); }

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg); }

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg); }

/* transitionEffect: circular */
.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0); }

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0); }

/* transitionEffect: tube */
.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg); }

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg); }

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1); }

/* Styling for Small-Screen Devices */
@media all and (max-height: 576px) {
  .fancybox-slide {
    padding-left: 6px;
    padding-right: 6px; }
  .fancybox-slide--image {
    padding: 6px 0; }
  .fancybox-close-small {
    right: -6px; }
  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px; }
  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px; }
  /* @supports (padding: max(0px)) {
        .fancybox-caption {
            padding-left: max(12px, env(safe-area-inset-left));
            padding-right: max(12px, env(safe-area-inset-right));
        }
    }*/ }

/* Share */
.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center; }

.fancybox-share h1, .fancybox-share .h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0; }

.fancybox-share p {
  margin: 0;
  padding: 0; }

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap; }

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff; }

.fancybox-share__button:hover {
  text-decoration: none; }

.fancybox-share__button--fb {
  background: #3b5998; }

.fancybox-share__button--fb:hover {
  background: #344e86; }

.fancybox-share__button--pt {
  background: #bd081d; }

.fancybox-share__button--pt:hover {
  background: #aa0719; }

.fancybox-share__button--tw {
  background: #1da1f2; }

.fancybox-share__button--tw:hover {
  background: #0d95e8; }

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px; }

.fancybox-share__button svg path {
  fill: #fff; }

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%; }

/* Thumbs */
.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995; }

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden; }

.fancybox-show-thumbs .fancybox-thumbs {
  display: block; }

.fancybox-show-thumbs .fancybox-inner {
  right: 212px; }

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%; }

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px; }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); }

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px; }

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px; }

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991; }

.fancybox-thumbs__list a:focus::before {
  opacity: .5; }

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1; }

/* Styling for Small-Screen Devices */
@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px; }
  .fancybox-show-thumbs .fancybox-inner {
    right: 110px; }
  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px); } }

/*! nouislider - 14.2.0 - 3/27/2020 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative; }

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1; }

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0; }

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  -webkit-transform-style: preserve-3d;
  transform-origin: 0 0;
  transform-style: flat; }

.noUi-connect {
  height: 100%;
  width: 100%; }

.noUi-origin {
  height: 10%;
  width: 10%; }

/* Offset direction
 */
.noUi-txt-dir-rtl.noUi-horizontal .noUi-origin {
  left: 0;
  right: auto; }

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0; }

.noUi-horizontal .noUi-origin {
  height: 0; }

.noUi-handle {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute; }

.noUi-touch-area {
  height: 100%;
  width: 100%; }

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px; }

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  right: -17px;
  top: -6px; }

.noUi-vertical {
  width: 18px; }

.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  right: -6px;
  top: -17px; }

.noUi-txt-dir-rtl.noUi-horizontal .noUi-handle {
  left: -17px;
  right: auto; }

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #FAFAFA;
  border-radius: 4px;
  border: 1px solid #D3D3D3;
  box-shadow: inset 0 1px 1px #F0F0F0, 0 3px 6px -5px #BBB; }

.noUi-connects {
  border-radius: 3px; }

.noUi-connect {
  background: #3FB8AF; }

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize; }

.noUi-vertical .noUi-draggable {
  cursor: ns-resize; }

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB; }

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB; }

/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: "";
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #E8E7E6;
  left: 14px;
  top: 6px; }

.noUi-handle:after {
  left: 17px; }

.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px; }

.noUi-vertical .noUi-handle:after {
  top: 17px; }

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed; }

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-pips {
  position: absolute;
  color: #999; }

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center; }

.noUi-value-sub {
  color: #ccc;
  font-size: 10px; }

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC; }

.noUi-marker-sub {
  background: #AAA; }

.noUi-marker-large {
  background: #AAA; }

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%; }

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%); }

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%); }

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px; }

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px; }

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px; }

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%; }

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding-left: 25px; }

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%); }

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px; }

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px; }

.noUi-marker-vertical.noUi-marker-large {
  width: 15px; }

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap; }

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%; }

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("../img/ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("../font/slick.eot");
  src: url("../font/slick.eot?#iefix") format("embedded-opentype"), url("../font/slick.woff") format("woff"), url("../font/slick.ttf") format("truetype"), url("../font/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: white;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: -25px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }

.slick-next {
  right: -25px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  padding: 0 24px;
  font-size: 15px;
  color: #fff;
  white-space: nowrap;
  border: 1px solid transparent;
  background-color: #ea3d56; }
  .btn [class^="icon-"]:before, .btn [class*=" icon-"]:before {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    font-size: .8em; }
  .btn:hover {
    color: #fff;
    text-decoration: none; }

.btn-outline {
  color: #fff;
  border-color: #ea3d56;
  background-color: transparent; }

.breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 13px; }
  .breadcrumb li {
    display: inline-block; }
  .breadcrumb li + li {
    padding-left: 2px; }
    .breadcrumb li + li:before {
      display: inline-block;
      padding-right: 4px;
      content: "/"; }

.pagination {
  display: flex;
  justify-content: center;
  color: #373737; }

.pagination__item,
.pagination__prev,
.pagination__next {
  margin: 0 3px;
  width: 38px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  font-weight: 600;
  background-color: #fff;
  box-shadow: 0 5px 29px rgba(0, 0, 0, 0.13);
  transition: background-color .3s; }
  .pagination__item:hover, .pagination__item.active,
  .pagination__prev:hover,
  .pagination__prev.active,
  .pagination__next:hover,
  .pagination__next.active {
    color: #fff;
    text-decoration: none;
    background-color: #ea3d56; }

.pagination__prev,
.pagination__next {
  font-size: 0; }
  .pagination__prev:before,
  .pagination__next:before {
    font-family: "fontello";
    font-size: 14px; }

.pagination__prev {
  margin-right: 6px; }
  .pagination__prev:before {
    margin-right: 2px;
    content: '\e800'; }

.pagination__next {
  margin-left: 6px; }
  .pagination__next:before {
    margin-left: 2px;
    content: '\e801'; }

.date-item {
  display: inline-block;
  height: 53px;
  padding: 0 22px;
  line-height: 53px;
  font-size: 20px;
  color: #fff;
  background-color: #ea3d56; }

.icon-auto:before {
  width: auto;
  margin-left: 0;
  margin-right: 0; }

@font-face {
  font-family: Montserrat;
  src: url(../font/Montserrat-ExtraBold.ttf);
  font-style: normal;
  font-weight: 800; }

@font-face {
  font-family: Montserrat;
  src: url(../font/Montserrat-Bold.ttf);
  font-style: normal;
  font-weight: bold; }

@font-face {
  font-family: Montserrat;
  src: url(../font/Montserrat-SemiBold.ttf);
  font-style: normal;
  font-weight: 600; }

@font-face {
  font-family: Montserrat;
  src: url(../font/Montserrat-Medium.ttf);
  font-style: normal;
  font-weight: 500; }

@font-face {
  font-family: Montserrat;
  src: url(../font/Montserrat-Regular.ttf);
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: Caveat;
  src: url(../font/Caveat-Bold.ttf);
  font-style: normal;
  font-weight: bold; }

body {
  font-family: Montserrat, sans-serif;
  color: #1c1c21;
  font-size: 14px; }

hr {
  margin: 20px 0;
  color: inherit;
  background-color: #e5e5e5;
  border: none;
  opacity: 1; }

a {
  color: inherit; }
  a:hover {
    color: inherit;
    text-decoration: none; }

button:focus {
  outline: none; }

b,
strong {
  font-weight: bold; }

img {
  max-width: 100%; }

sub {
  bottom: 0; }

.form-group {
  margin-bottom: 10px; }

.form-label {
  margin-bottom: 8px;
  margin-left: 5px;
  font-weight: 600; }

.form-item {
  position: relative; }
  .form-item [class^="icon-"], .form-item [class*=" icon-"] {
    position: absolute;
    top: 50%;
    right: 14px;
    margin-top: -8px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: right; }
    .form-item [class^="icon-"]:before, .form-item [class*=" icon-"]:before {
      margin: 0; }
  .form-item .form-control {
    padding-right: 44px; }

.form-control {
  padding: 11px 20px; }
  .form-control:focus {
    box-shadow: none; }

textarea.form-control {
  min-height: 170px;
  max-height: 200px; }

p {
  margin-bottom: 20px; }
  p:last-child {
    margin-bottom: 0; }

h1, .h1, .h1, h2, .h2, .h2, h3, .h3, .h3, h4, .h4, .h4, h5, .h5, .h5, h6, .h6, .h6 {
  margin-bottom: 20px;
  font-weight: bold; }
  h1:last-child, .h1:last-child, .h1:last-child, h2:last-child, .h2:last-child, .h2:last-child, h3:last-child, .h3:last-child, .h3:last-child, h4:last-child, .h4:last-child, .h4:last-child, h5:last-child, .h5:last-child, .h5:last-child, h6:last-child, .h6:last-child, .h6:last-child {
    margin-bottom: 0; }
  h1 strong, .h1 strong, .h1 strong, h2 strong, .h2 strong, .h2 strong, h3 strong, .h3 strong, .h3 strong, h4 strong, .h4 strong, .h4 strong, h5 strong, .h5 strong, .h5 strong, h6 strong, .h6 strong, .h6 strong {
    height: 38px;
    padding: .25em .6em;
    color: #fff;
    font-weight: normal;
    background-color: #ea3d56; }

.h1 {
  font-size: 65px; }

.h2 {
  font-size: 50px; }

.h3 {
  font-size: 35px; }

.h4 {
  font-size: 28px; }

.h5 {
  line-height: 38px;
  font-size: 25px; }

.h6 {
  font-size: 20px; }

.typography {
  line-height: 24px;
  font-size: 14px; }
  .typography h1, .typography .h1, .typography h2, .typography .h2, .typography h3, .typography .h3, .typography h4, .typography .h4, .typography h5, .typography .h5, .typography h6, .typography .h6,
  .typography p, .typography ul, .typography ol, .typography blockquote, .typography img {
    margin-bottom: 24px; }
    .typography h1:last-child, .typography .h1:last-child, .typography h2:last-child, .typography .h2:last-child, .typography h3:last-child, .typography .h3:last-child, .typography h4:last-child, .typography .h4:last-child, .typography h5:last-child, .typography .h5:last-child, .typography h6:last-child, .typography .h6:last-child,
    .typography p:last-child, .typography ul:last-child, .typography ol:last-child, .typography blockquote:last-child, .typography img:last-child {
      margin-bottom: 0; }
  .typography a {
    text-decoration: underline; }
  .typography ul {
    margin-bottom: 20px;
    list-style: none;
    padding-left: 38px; }
    .typography ul:last-child {
      margin-bottom: 0; }
    .typography ul li {
      margin-bottom: 8px; }
      .typography ul li:before {
        position: absolute;
        content: '';
        margin-top: 8px;
        margin-left: -20px;
        width: 6px;
        height: 6px;
        background-color: #ea3d56; }
  .typography blockquote {
    position: relative;
    margin-top: 40px;
    padding: 60px 60px 50px;
    line-height: 27px;
    font-size: 17px;
    background-color: #f7f8fa; }
    .typography blockquote:after {
      position: absolute;
      content: '';
      top: -19px;
      left: 60px;
      width: 56px;
      height: 46px;
      background: url(../img/quote-2.png) center no-repeat;
      background-size: contain; }
  .typography:first-child {
    margin-top: 20px; }

#page {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  min-width: 1000px;
  min-height: 100vh;
  overflow: hidden; }

#page-content {
  flex-grow: 1;
  margin-top: 120px; }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  max-width: 1200px; }

.row-gallery {
  margin: 0 -3px -6px; }
  .row-gallery > .col,
  .row-gallery > [class*="col-"] {
    margin-bottom: 6px;
    padding-right: 3px;
    padding-left: 3px; }

.group-select {
  display: flex;
  margin-right: -20px; }
  .group-select .btn {
    margin-right: 20px;
    color: inherit;
    border: 2px solid #e0e0e0;
    background-color: transparent; }
    .group-select .btn.active {
      color: #fff;
      font-weight: bold;
      border-color: transparent;
      background-color: #ea3d56; }

.logo {
  display: inline-block;
  width: 100%;
  max-width: 200px; }
  .logo img {
    width: 100%; }

.h-decor {
  margin-bottom: 40px; }
  .h-decor:after {
    display: block;
    margin: 20px auto 0;
    content: '';
    width: 36px;
    height: 7px;
    background-color: #ea3d56; }

.text-label {
  display: inline-block;
  padding: 8px 20px;
  color: #fff;
  background-color: #eb3d56; }

.text-underline {
  display: inline-block;
  padding-bottom: 2px;
  border-bottom: 1px solid #eb3d56; }

header {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 120px;
  color: #fff;
  background-color: transparent;
  z-index: 10;
  transition: background-color .3s, box-shadow .3s; }
  header.sticky {
    background-color: rgba(0, 0, 0, 0.8);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2); }

.contacts-group {
  position: relative;
  padding-left: 42px; }
  .contacts-group:before {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -17px;
    font-family: "fontello";
    content: '\e802';
    width: 34px;
    height: 34px;
    line-height: 34px;
    font-size: 16px;
    text-align: center;
    border-radius: 50%;
    background-color: #ea3d56; }

.header-contact {
  font-size: 18px; }

.header-nav {
  display: flex;
  list-style: none;
  margin: 0;
  margin-top: 16px;
  margin-right: -34px;
  padding: 0; }
  .header-nav li {
    margin-right: 34px; }
  .header-nav a {
    transition: color .3s; }
    .header-nav a:hover {
      color: #ea3d56; }

footer {
  color: #fff;
  background-color: #27262c; }

.footer__main {
  padding-top: 20px;
  padding-bottom: 20px; }

.footer__bottom {
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  background-color: #232227; }

.footer-list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 120px;
  font-size: 14px; }
  .footer-list li {
    margin-bottom: 12px; }
  .footer-list a {
    transition: color .3s; }
    .footer-list a:hover {
      color: #ea3d56;
      text-decoration: none; }

.contact-item {
  position: relative;
  margin-bottom: 8px;
  padding-left: 30px;
  font-size: 16px; }
  .contact-item [class^="icon-"], .contact-item [class*=" icon-"] {
    position: absolute;
    top: -2px;
    left: 0;
    font-size: 18px;
    color: #ea3d56; }
    .contact-item [class^="icon-"]:before, .contact-item [class*=" icon-"]:before {
      margin: 0; }

.btn-social {
  height: 40px;
  padding: 0 18px;
  border-radius: 20px;
  font-size: 13px;
  color: #fff;
  border: none;
  background-color: #6585c2; }

.wrapper-slider {
  position: relative;
  min-width: 0;
  padding-left: 20px;
  padding-right: 20px; }
  .wrapper-slider .slider-controls {
    position: absolute;
    justify-content: space-between;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
    margin: 0; }

.slick-vertical .slick-slide {
  border: none; }

.slider {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden; }
  .slider > div {
    flex: 0 0 100%;
    width: 100%; }

.slider-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px; }

.slider-prev,
.slider-next {
  width: 38px;
  height: 36px;
  line-height: 36px;
  font-size: 0;
  text-align: center;
  border: none;
  background-color: #fff;
  box-shadow: 0 5px 29px rgba(0, 0, 0, 0.13); }
  .slider-prev:before,
  .slider-next:before {
    font-family: "fontello";
    font-size: 14px; }
  .slider-prev:hover,
  .slider-next:hover {
    color: #fff;
    background-color: #ea3d56; }

.slider-next {
  margin-left: 30px; }

.slider-prev:before {
  content: '\e800'; }

.slider-next:before {
  content: '\e801'; }

.slick-initialized .slick-slide {
  display: flex; }

.slick-list {
  width: 100%; }

.slick-track {
  display: flex; }

.slick-slide {
  float: none; }
  .slick-slide > div {
    flex: 0 0 100%;
    width: 100%; }
    .slick-slide > div > [class*="col-"] {
      flex-basis: 100%;
      max-width: 100%; }

.main {
  position: relative;
  display: flex;
  margin-top: -120px;
  min-height: 100vh;
  padding-top: 120px;
  padding-bottom: 120px;
  color: #fff;
  background: center no-repeat;
  background-size: cover; }
  .main:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color: rgba(32, 32, 36, 0.64); }
  .main .text-label {
    padding: 8px 30px;
    font-size: 27px; }

.btn-request {
  position: absolute;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  right: 15px;
  bottom: -78px;
  height: 58px;
  padding: 0 20px;
  color: #fff;
  border-radius: 29px;
  border: none;
  background-color: #ea3d56; }
  .btn-request > span {
    position: relative;
    z-index: 1; }
  .btn-request:before, .btn-request:after {
    position: absolute;
    content: '';
    border-radius: 41px; }
  .btn-request:before {
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    background-color: rgba(234, 61, 86, 0.59); }
  .btn-request:after {
    top: -12px;
    left: -12px;
    right: -12px;
    bottom: -12px;
    background-color: rgba(234, 61, 86, 0.37); }

.catalog-card {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-bottom: 6px;
  min-height: 494px;
  color: #fff;
  text-align: center;
  background: center no-repeat;
  background-size: cover; }
  .catalog-card:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(32, 32, 36, 0.55); }

.catalog-card__content {
  position: relative;
  width: 100%;
  padding: 44px 20px; }

.catalog-card__label {
  display: inline-block;
  margin-bottom: 14px;
  height: 36px;
  padding: 0 15px;
  line-height: 36px;
  font-size: 16px;
  background-color: #ea3d56; }

.catalog-card__title {
  margin-bottom: 20px;
  line-height: 33px;
  font-size: 23px;
  font-weight: 600; }

.catalog-card__price {
  font-size: 33px;
  font-weight: 800; }

.catalog-card__service {
  display: inline-block;
  margin-bottom: 40px;
  padding-bottom: 2px;
  font-size: 14px;
  border-bottom: 1px solid #ea3d56; }

.work-slider {
  margin: 0 auto;
  max-width: 1000px; }

.work-card {
  display: flex !important;
  padding: 9px 11px 0; }

.work-card__img {
  position: relative;
  width: 53%;
  margin-right: -110px; }
  .work-card__img:before {
    position: absolute;
    content: '';
    top: -9px;
    left: -11px;
    width: 62px;
    height: 58px;
    background-color: #ea3d56; }
  .work-card__img img {
    position: relative;
    width: 100%; }

.work-card__content {
  width: 65%;
  margin-top: 58px;
  padding: 44px 44px 60px 160px;
  background-color: #fff;
  box-shadow: 0 5px 29px rgba(0, 0, 0, 0.03); }

.work-card__title {
  margin-bottom: 20px;
  font-size: 25px; }
  .work-card__title strong {
    display: inline-block;
    margin: 8px 0;
    height: 39px;
    padding: 0 14px;
    color: #fff;
    background: #ea3d56; }

.work-card__text {
  line-height: 24px;
  font-size: 14px; }

.form {
  margin: 0 auto;
  width: 770px;
  padding: 30px 56px;
  background-color: #fff;
  box-shadow: 0 5px 29px rgba(0, 0, 0, 0.07); }

.map {
  display: flex;
  margin-top: 30px;
  min-height: 470px; }

.map__content {
  display: flex;
  flex-direction: column;
  width: 40%; }

.map__map {
  min-height: 330px;
  flex: 1 1 auto; }

.map__contacts {
  padding: 26px 20px;
  color: #fff;
  border-left: 6px solid #ea3d56;
  background-color: #202024; }

.map__view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  color: #fff;
  background: center no-repeat;
  background-size: cover; }

.map__view-icon {
  line-height: 50px;
  font-size: 50px; }

.map__link {
  font-size: 15px;
  border-bottom: 1px solid #ea3d56; }

.distance-item {
  margin-left: 10px;
  line-height: 60px;
  font-size: 25px;
  font-weight: 600; }
  .distance-item strong {
    font-size: 50px;
    font-weight: 800; }

.address-item {
  position: relative;
  margin-bottom: 16px;
  padding-left: 33px;
  font-size: 13px; }
  .address-item:last-child {
    margin-bottom: 0; }

.address-item__label {
  position: absolute;
  top: -2px;
  left: 0;
  width: 22px;
  height: 22px;
  line-height: 22px;
  font-size: 11px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  background-color: #3a9cc7; }

.feedback-card {
  display: flex;
  margin-right: 20px;
  padding-bottom: 50px;
  min-height: 276px; }

.feedback-card__name {
  position: absolute;
  bottom: 0;
  left: 22px; }

.feedback-card__img {
  min-width: 200px;
  max-width: 200px;
  background: center no-repeat;
  background-size: cover; }

.feedback-card__content {
  position: relative;
  padding: 50px 20px 30px 60px;
  background-color: #fff;
  box-shadow: 0 5px 29px rgba(0, 0, 0, 0.07); }
  .feedback-card__content:before {
    position: absolute;
    content: '';
    top: 28px;
    left: 20px;
    right: -20px;
    bottom: -16px;
    border: 1px solid #ea3d56; }
  .feedback-card__content:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 56px;
    height: 58px;
    background: #fff url(../img/quote.png) center 20px no-repeat;
    background-size: 28px 22px; }

.feedback-card__text {
  line-height: 24px;
  font-size: 14px; }

.news-card {
  position: relative; }

.news-card__date {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 95px;
  height: 37px;
  padding: 0 16px;
  line-height: 37px;
  font-size: 13px;
  color: #fff;
  background-color: #6585c2; }

.news-card__img {
  position: relative; }
  .news-card__img:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(32, 32, 36, 0.1); }
  .news-card__img img {
    width: 100%; }

.news-card__content {
  padding: 14px 10px; }

.news-card__title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold; }

.news-card__data-item {
  position: relative;
  margin-bottom: 4px;
  padding-left: 20px; }
  .news-card__data-item [class^="icon-"], .news-card__data-item [class*=" icon-"] {
    position: absolute;
    top: 2px;
    left: 0;
    font-size: 12px;
    color: #ea3d56; }
    .news-card__data-item [class^="icon-"]:before, .news-card__data-item [class*=" icon-"]:before {
      margin: 0; }

.news-wide {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 auto 50px;
  max-width: 800px; }
  .news-wide .news-card__date {
    left: 26px; }
  .news-wide .news-card__img {
    width: 50%; }
  .news-wide .news-card__content {
    width: 50%;
    padding-left: 30px; }
  .news-wide .news-card__title {
    font-size: 22px; }
  .news-wide:nth-child(2n + 2) .news-card__content {
    order: -1;
    padding-left: 0;
    padding-right: 30px; }
  .news-wide:last-child {
    margin-bottom: 0; }

.event-card {
  display: flex; }

.event-card__sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-width: 140px;
  padding: 20px;
  background-color: #eff0f1; }

.event-card__content {
  flex-grow: 1;
  min-width: 0;
  padding-top: 20px;
  padding-left: 40px; }

.event-card__nav {
  position: relative;
  width: 30%;
  padding-right: 34px;
  z-index: 1; }

.event-card__card {
  position: relative;
  width: 70%;
  padding: 40px 40px 30px 80px;
  background-color: #fff;
  box-shadow: 0 5px 29px rgba(0, 0, 0, 0.07); }

.event-card__card-tab {
  padding-right: 40px; }

.event-card__count {
  position: relative;
  align-self: center;
  width: 140px;
  min-width: 138px;
  height: 115px;
  line-height: 115px;
  font-size: 25px;
  font-style: italic;
  text-align: center;
  border: 1px solid #e63d54;
  border-left: none; }
  .event-card__count .slider-controls {
    position: absolute;
    flex-direction: column;
    top: 50%;
    margin-top: -38px;
    margin-left: -17px; }
  .event-card__count .slider-next {
    margin-left: 0;
    margin-top: 4px; }

.event-logo {
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase; }
  .event-logo img {
    display: block;
    margin: 10px auto; }

.event-date {
  position: relative;
  padding-left: 13px;
  line-height: 16px;
  font-size: 12px; }
  .event-date:before {
    position: absolute;
    content: '';
    top: 4px;
    left: 0;
    bottom: 4px;
    width: 4px;
    background-color: #ea3d56; }

.event-nav {
  list-style: none;
  margin: -14px 0;
  max-width: 200px;
  padding: 0; }
  .event-nav li {
    margin: 40px 0; }
  .event-nav a {
    position: relative;
    display: block;
    text-decoration: underline; }
    .event-nav a:before {
      position: absolute;
      content: '';
      top: -8px;
      left: -14px;
      right: -14px;
      bottom: -8px;
      border: 1px solid #ea3d56;
      opacity: 0; }
    .event-nav a:after {
      position: absolute;
      content: '';
      top: 50%;
      right: -104px;
      width: 90px;
      height: 1px;
      background-color: #ea3d56;
      opacity: 0; }
    .event-nav a.active {
      color: #ea3d56;
      font-weight: bold; }
      .event-nav a.active:before, .event-nav a.active:after {
        opacity: 1; }

.focument-item,
.video-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  color: #fff;
  background: center no-repeat;
  background-size: cover; }
  .focument-item:before,
  .video-item:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(36, 36, 43, 0.6); }

.focument-item:before {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "fontello";
  content: '\e809';
  font-size: 20px; }

.video-item__play {
  display: flex;
  width: 50px;
  height: 50px;
  padding-left: 6px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.11); }
  .video-item__play:after {
    display: block;
    content: '';
    margin: auto;
    border-top: 8px solid transparent;
    border-left: 13px solid #ec5269;
    border-bottom: 8px solid transparent; }

.about {
  position: relative;
  display: flex; }
  .about:before {
    position: absolute;
    content: '';
    top: 85px;
    right: 0;
    width: 70%;
    height: 330px;
    background-color: #eb3d56; }

.about__cards {
  position: relative;
  flex-grow: 1;
  margin-right: -24%;
  z-index: 1; }

.about__img {
  position: relative;
  margin-top: 240px;
  min-width: 41%; }
  .about__img img {
    width: 86%;
    box-shadow: 0 5px 29px rgba(0, 0, 0, 0.03); }

.about-card {
  position: relative;
  padding: 50px 30px 60px 50px; }
  .about-card:before {
    position: absolute;
    top: 12px;
    right: 30px;
    line-height: 80px;
    font-family: Caveat, sans-serif;
    font-size: 80px;
    font-weight: bold;
    opacity: .11; }

.about-card-1 {
  margin-left: 43%;
  width: 57%;
  padding-left: 70px;
  background-color: #fff;
  box-shadow: 0 5px 29px rgba(0, 0, 0, 0.13); }
  .about-card-1:before {
    content: '01'; }

.about-card-2 {
  margin-top: -180px;
  margin-left: 3%;
  width: 45%;
  padding-bottom: 100px;
  color: #fff;
  background-color: #32323a;
  box-shadow: 0 5px 29px rgba(0, 0, 0, 0.13); }
  .about-card-2:before {
    content: '02'; }

.about-card-3 {
  margin-top: -25px;
  margin-left: 8%;
  width: 57%;
  padding-bottom: 0; }
  .about-card-3:before {
    top: 42px;
    content: '03'; }
  .about-card-3:after {
    position: absolute;
    top: -40px;
    left: 0;
    bottom: 32px;
    content: '';
    width: 8px;
    background-color: #ea3d56; }

.about-card__title {
  margin-bottom: 10px;
  font-family: Caveat, sans-serif;
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase; }

.about-card__links {
  display: flex;
  margin-right: -20px;
  margin-bottom: 20px; }

.about-card__link {
  margin-right: 20px;
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 2px solid #ea3d56; }

.about-card__text {
  line-height: 24px;
  font-size: 14px; }

.performance-card {
  margin: 0 auto 50px;
  max-width: 440px; }

.performance-card__img {
  margin-bottom: 20px; }

.performance-card__title {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: bold; }

.performance-card__data {
  position: relative;
  list-style: none;
  margin-bottom: 14px;
  padding-left: 18px;
  font-size: 15px; }
  .performance-card__data:before {
    position: absolute;
    content: '';
    top: 4px;
    left: 0;
    bottom: 4px;
    width: 4px;
    background-color: #ea3d56; }

.performance-card__text {
  padding: 4px 0 16px;
  line-height: 24px;
  font-size: 14px; }

.performance-card__detail {
  margin-right: 10px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #e2dfe0; }

.publication-card {
  display: flex;
  margin-bottom: 20px;
  min-height: 210px;
  padding: 20px;
  box-shadow: 0 5px 29px rgba(0, 0, 0, 0.09); }
  .publication-card:last-child {
    margin-bottom: 0; }

.publication-card__img {
  width: 164px;
  min-width: 164px;
  background: #eee center no-repeat;
  background-size: cover; }

.publication-card__content {
  flex-grow: 1;
  padding: 12px 30px; }

.publication-card__date {
  margin-bottom: 12px;
  font-size: 13px;
  font-weight: 500; }

.publication-card__title {
  margin-bottom: 12px;
  font-size: 20px;
  font-weight: bold; }

.publication-card__text {
  line-height: 24px;
  font-size: 14px; }

.publication-card__more {
  display: flex;
  margin: -20px;
  margin-left: 0;
  width: 134px;
  min-width: 134px;
  background-color: #e8e8e9; }
  .publication-card__more:before {
    display: block;
    content: '\e801';
    margin: auto;
    width: 72px;
    height: 52px;
    line-height: 52px;
    font-family: "fontello";
    font-size: 24px;
    color: #fff;
    text-align: center;
    background-color: #ea3d56;
    transition: transform .3s; }
