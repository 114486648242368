.btn {
	display: inline-flex;
	justify-content: center;
	align-items: center;
	height: 45px;
	padding: 0 24px;
	font-size: 15px;
	color: #fff;
	white-space: nowrap;
	border: 1px solid transparent;
	background-color: #ea3d56;
	// transition: color .3s, border-color .3s, background-color .3s;

	[class^="icon-"]:before, [class*=" icon-"]:before {
	  width: auto;
	  margin-left: 0;
	  margin-right: 0;
	  font-size: .8em;
 	}

	&:hover {
		color: #fff;
		text-decoration: none;
		// background-color: darken(#12924d, 12%);
	}
}

.btn-outline {
	color: #fff;
	border-color: #ea3d56;
	background-color: transparent;
}

// .btn-sm {
// 	height: 50px;
// 	padding: 0 22px;
// 	font-size: 14px;
// }

// .btn-outline {
// 	color: #12924d;
// 	border-color: #12924d;
// 	background-color: transparent;
// 	&:hover {
// 		color: #fff;
// 		border-color: darken(#12924d, 12%);
// 		background-color: darken(#12924d, 12%);
// 	}
// }

// .link {
// 	text-decoration: underline;
// 	&:hover {
// 		text-decoration: none;
// 	} 
// }

// .btn-check {
// 	vertical-align: middle;
// 	margin: 0;
// }

// .btn-check__input {
// 	display: none;
// }

// .btn-check__input:checked + .btn-check__label {
// 	border-color: #12924d;
// 	&:before {
// 		content: '\e802';
// 		background-color: #12924d;
// 	}
// }

// .btn-check__label {
// 	display: inline-flex;
// 	align-items: center;
// 	height: 64px;
// 	padding: 0 37px;
// 	font-size: 12px;
// 	text-transform: uppercase;
// 	border-radius: 10px;
// 	border: 1px solid #dedede;
// 	cursor: pointer;
// 	&:before {
// 		display: inline-block;
// 		content: '';
// 		margin-right: 12px;
// 		width: 20px;
// 		height: 20px;
// 		line-height: 20px;
// 		font-family: "fontello";
// 		font-size: 10px;
// 		color: #fff;
// 		border-radius: 50%;
// 		background-color: #e3e0e0;
// 	}
// }
