.feedback-card {
	display: flex;
	margin-right: 20px;
	padding-bottom: 50px;
	min-height: 276px;
}

.feedback-card__name {
	position: absolute;
	bottom: 0;
	left: 22px;
}

.feedback-card__img {
	min-width: 200px;
	max-width: 200px;
	background: center no-repeat;
	background-size: cover;
}

.feedback-card__content {
	position: relative;
	padding: 50px 20px 30px 60px;
	background-color: #fff;
	box-shadow: 0 5px 29px rgba(0, 0, 0, .07);
	&:before {
		position: absolute;
		content: '';
		top: 28px;
		left: 20px;
		right: -20px;
		bottom: -16px;
		border: 1px solid #ea3d56;
	}
	&:after {
		position: absolute;
		content: '';
		top: 0;
		left: 0;
		width: 56px;
		height: 58px;
		background: #fff url(../img/quote.png) center 20px no-repeat;
		background-size: 28px 22px;
	}
}

.feedback-card__text {
 	line-height: 24px;
 	font-size: 14px;
}