.work-slider {
	margin: 0 auto;
	max-width: 1000px;
}

.work-card {
	display: flex !important;
	padding: 9px 11px 0;
}

.work-card__img {
	position: relative;
	width: 53%;
	margin-right: -110px;
	&:before {
		position: absolute;
		content: '';
		top: -9px;
		left: -11px;
		width: 62px;
		height: 58px;
		background-color: #ea3d56;
	}
	img {
		position: relative;
		width: 100%;
	}
}

.work-card__content {
	width: 65%;
	margin-top: 58px;
	padding: 44px 44px 60px 160px;
	background-color: #fff;
	box-shadow: 0 5px 29px rgba(0, 0, 0, .03);
}

.work-card__title {
	margin-bottom: 20px;
	font-size: 25px;
	strong {
		display: inline-block;
		margin: 8px 0;
		height: 39px;
		padding: 0 14px;
		color: #fff;
		background: #ea3d56;
	}
}

.work-card__text {
	line-height: 24px;
	font-size: 14px;
}




