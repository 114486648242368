.wrapper-slider {
	position: relative;
	min-width: 0;
	padding-left: 20px;
	padding-right: 20px;
	.slider-controls {
		position: absolute;
		justify-content: space-between;
		top: 50%;
		left: 0;
		width: 100%;
		height: 0;
		margin: 0;
	}
}

// .slider-card {
// 	.slider-nav {
// 		display: flex;
// 		margin-top: 15px;
// 		height: 80px;
// 		overflow: hidden;
// 		.slick-slide {
// 			.slide {
// 				flex-basis: 100%;
// 				width: 100%;
// 			}
// 		}
// 		.slide {
// 			justify-content: center;
// 			align-items: center;
// 			height: 80px;
// 		}
// 	}
// 	.slide {
// 		height: 270px;
// 	}
// }

// .slider-vertical {
// 	display: flex;
// 	.slider-nav {
// 		margin-right: 15px;
// 		min-width: 80px;
// 		height: 270px;
// 		overflow: hidden;
// 		.slick-track {
// 			flex-direction: column;
// 		}
// 		.slide {
// 			justify-content: center;
// 			align-items: center;
// 			margin-bottom: 15px;
// 			height: 80px;
// 		}
// 	}
// 	.slide {
// 		height: 270px;
// 	}
// }

.slick-vertical .slick-slide {
	border: none;
}

.slider {
// .slider-gallery {
	display: flex;
	flex-wrap: nowrap;
	overflow: hidden;
	> div {
		flex: 0 0 100%;
		width: 100%;
	}
}

// .slider-gallery {
// 	flex-wrap: nowrap;

// 	.slick-slide {
// 		height: auto;
// 	}
// }

// .slide {
// 	flex: 0 0 100%;
// 	width: 100%;
// }

.slider-controls {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 20px;
}

.slider-prev,
.slider-next {
	width: 38px;
	height: 36px;
	line-height: 36px;
	font-size: 0;
	text-align: center;
	border: none;
	background-color: #fff;
	box-shadow: 0 5px 29px rgba(0, 0, 0, .13);
	&:before {
		font-family: "fontello";
		font-size: 14px;
	}
	&:hover {
		color: #fff;
		background-color: #ea3d56;
	}
}

.slider-next {
	margin-left: 30px;
}

.slider-prev {
	&:before {
		content: '\e800';
	}	
}

.slider-next {
	&:before {
		content: '\e801';
	}
}

.slick-initialized .slick-slide {
	display: flex;
}

.slick-list {
	width: 100%;
}

.slick-track {
  display: flex;
}

.slick-slide {
	float: none;
	> div {
		flex: 0 0 100%;
		width: 100%;
		// flex-grow: 1;
		// display: flex;
		> [class*="col-"] {
			// display: flex !important;
    	flex-basis: 100%;
    	max-width: 100%;
		}
	}
}
