.map {
	display: flex;
	margin-top: 30px;
	min-height: 470px;
}

.map__content {
	display: flex;
	flex-direction: column;
	width: 40%;
}

.map__map {
	min-height: 330px;
	flex: 1 1 auto;
}

.map__contacts {
	padding: 26px 20px;
	color: #fff;
	border-left: 6px solid #ea3d56;
	background-color: #202024;
}

.map__view {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 60%;
	color: #fff;
	background: center no-repeat;
	background-size: cover;
}

.map__view-icon {
	line-height: 50px;
	font-size: 50px;
}

.map__link {
	font-size: 15px;
	border-bottom: 1px solid #ea3d56;
}

.distance-item {
	margin-left: 10px;
	line-height: 60px;
	font-size: 25px;
	font-weight: 600;
	strong {
		font-size: 50px;
		font-weight: 800;
	}
}

.address-item {
	position: relative;
	margin-bottom: 16px;
	padding-left: 33px;
	font-size: 13px;
	&:last-child {
		margin-bottom: 0;
	}
}

.address-item__label {
	position: absolute;
	top: -2px;
	left: 0;
	width: 22px;
	height: 22px;
	line-height: 22px;
	font-size: 11px;
	color: #fff;
	text-align: center;
	border-radius: 50%;
	background-color: #3a9cc7;
} 