.breadcrumb {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 13px;
  li {
    display: inline-block;
  }
  li + li {
		padding-left: 2px;
  	&:before {
			display: inline-block;
	  	padding-right: 4px;
	  	content: "/";
		}
  }
}