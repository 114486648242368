.performance-card {
	margin: 0 auto 50px;
	max-width: 440px;
}

.performance-card__img {
	margin-bottom: 20px;
}

.performance-card__title {
	margin-bottom: 10px;
	font-size: 22px;
	font-weight: bold;
}

.performance-card__data {
	position: relative;
	list-style: none;
	margin-bottom: 14px;
	padding-left: 18px;
	font-size: 15px;
	&:before {
		position: absolute;
		content: '';
		top: 4px;
		left: 0;
		bottom: 4px;
		width: 4px;
		background-color: $red;
	}
}

.performance-card__text {
	padding: 4px 0 16px;
	line-height: 24px;
	font-size: 14px;
}

.performance-card__detail {
	margin-right: 10px;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background-color: #e2dfe0;
}

.performance-card__text {

}